.box {
  -webkit-box-shadow: 0 0 5px rgba(0,0,0, 0.2);
  -moz-box-shadow: 0 0 5px rgba(0,0,0, 0.2);
  box-shadow: 0 0 5px rgba(0,0,0, 0.2);
  padding: 1.17rem;
  background: #fff;
}

/* Link with arrow */
.arrow-link {
  text-decoration: none;
  font-size: 0.76rem;
  text-transform: uppercase;
  font-weight: bold;
  display: inline-block;

  &:after {
    content: "\f105";
    font-weight: normal;
    line-height: 1rem;
    padding-left: 5px;
    display: inline-block;
    *zoom: 1;
    *display: inline;
    vertical-align: text-top;
    font-family: FontAwesome;
  }
  &.arrow-link-down::after {
    content: '\f107';
  }
  &.arrow-link-up::after {
    content: '\f106';
  }
}

.page .button.button-add {
  padding: 0.5rem 0.8rem 0.3rem 1.8rem;
  border-radius: 2px;
  position: relative;
  &::before {
    content: '+';
    padding-right: 0.5rem;
    font-size: 1rem;
    line-height: 0.75rem;
    position: absolute;
    left: 0.75rem;
    top: 0.85rem;
  }
}

/* Default wysiwyg styles */
.intro {
  font-style: italic;
  font-size: 1.05rem;
  line-height: 2rem;
  font-family: Lora, Georgia, "Times New Roman", Times, serif;
}
.quote {
  font-family: Lora, Georgia, "Times New Roman", Times, serif;
  font-style: italic;
  color: $orange;
  font-size: 1.05rem;
  line-height: 1.7rem;
  padding-left: 5%;
  padding-right: 5%;
  margin: 1rem auto;
  position: relative;
  &::before {
    content: open-quote;
    font-size: 2.6rem;
    color: $darkblue;
    position: absolute;
    left: 1%;
    top: 0.6rem;
  }
  &::after {
    content: close-quote;
    height: 0;
    width: 0;
    overflow: hidden;
    display: block;
  }
  @include media-breakpoint-up(sm) {
    margin: 2rem auto;
  }
}
.quote2 {
  font-family: Lora, Georgia, "Times New Roman", Times, serif;
  font-style: italic;
  color: $orange;
  font-size: 1.85rem;
  line-height: 2.3rem;
  padding-left: 6%;
  padding-right: 5%;
  margin: 1rem auto;
  position: relative;
  &::before {
    content: open-quote;
    font-size: 3.6rem;
    color: $darkblue;
    position: absolute;
    left: 1%;
    top: 0.6rem;
  }
  &::after {
    content: close-quote;
    height: 0;
    width: 0;
    overflow: hidden;
    display: block;
  }
  @include media-breakpoint-up(sm) {
    margin: 2rem auto;
  }
}
ul.checks {
  font-style: italic;
  font-size: 1.05rem;
  line-height: 2rem;
  list-style: none;
  margin-left: 0;
  font-family: Lora, Georgia, "Times New Roman", Times, serif;
  li::before {
    content: '\f00c';
    color: $lightblue;
    font-family: FontAwesome;
    padding-right: 0.6rem;
    font-size: 0.8rem;

  }
}
p.meta {
  font-size: 0.8rem;
}
p.columns {
  @include media-breakpoint-up(sm) {
    columns: 2;
  }
}
h2.red-title,
h3.red-title {
  color: $orange;
  font-size: 1.17rem;
  margin: 1rem 0 0.5rem;
  text-transform: uppercase;
  &::after {
    content: none;
  }
}