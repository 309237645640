/* some updates for the panopoly widgets popup */
#modal-content {

  .form-actions {
    clear: both;
    float: none;
    margin-left: 0;
    text-align: left;
    width: auto;
  }
  
  .form-item > label {
    font-size: 1.2em;
  }
  .form-item .form-item > label,
  .form-item > label.option {
    font-size: 1em;
  }
  .media-widget a {
    margin-right: 1em;
  }
  
  .panels-section-column-categories .content-type-button a {
    padding: 5px 8px;
    width: 95%;
  }
  .panels-section-column-categories .content-type-button img {
    display: none;
  }
  .panels-section-column-categories .content-type-button span {
    top: 0;
    margin-top: 0;
  }
  .button {
    background: -moz-linear-gradient(center bottom , #e9eaec 0%, #fafafa 100%) repeat scroll 0 0 #fafafa;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 3px 3px 0 #d2d2d2;
    color: #666;
    cursor: pointer;
    font: bold 12px/32px "Open Sans","Lucida Sans","Lucida Grande",Verdana,sans-serif;
    height: 33px;
    margin-bottom: 0.5em;
    margin-right: 1em;
    outline: medium none;
    padding: 0 13px 1px;
    text-decoration: none;
    display: inline-block;
    *zoom: 1;
    *display: inline;
  }
  .link-field-subrow,
  .form-type-link-field .field-suffix {
    display: inline-block;
    *zoom: 1;
    *display: inline;
    vertical-align: middle;
  }
  .link-field-column .form-text {
    width: 97%;
  }
  .link-field-column {
    float: left;
    width: 50%;
  }
  .vertical-tabs {
    //display: none;
  }
  // FAQ
  .field-name-field-qa {
    thead {
      display: none;
    }
    .format-toggle {
      display: none;
    }
    .form-text {
      width: 100%;
    }
  }
}

.panels-ipe-editing .panels-ipe-region {
  float: none;
}

ul.panels-ipe-linkbar {
  list-style: none;
  margin: 0;
  li {
    display: inline-block;
  }
}

.tabledrag-handle .handle, .mceToolbar table td a {
  box-sizing: content-box;
}

/* Keep this in sync with epsenkaas.admin.css */
.ui-widget-overlay {
  background-repeat: repeat;
  position: fixed;
  z-index: 1001;
}
.ui-widget {
  z-index: 1002;
}

.link-attributes {
  display: inline-block;
}
/* ctools FPP */
.manualcrop-style-button-holder {
  display: inline-block;
}
