.node-nieuws {

  // General fields
  .meta {
    font-style: italic;
    font-size: 0.9rem;
    > div {
      display: inline-block;
      &::before {
        content: "-";
        padding: 0 5px;
      }
      &:first-child::before {
        content: none;
      }
    }
  }


  // Title
  &.view-mode-title {
    position: relative;
    border-bottom: 1px solid $darkblue;
    padding: 0.8rem;
    background: #fff;
    margin: 0;
    &:last-child {
      border-bottom: 0 none;
    }
    p {
      margin: 0;
    }
    .field-name-title a {
      color: $darkblue;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.82rem;
    }
    .field-name-post-date {
      position: relative;
      padding-left: 30px;
      font-size: 0.82rem;
      font-style: italic;
      &::before {
        content: '\f133';
        font-family: FontAwesome;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5rem;
        font-size: 0.94rem;
        font-style: normal;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  // Square
  &.view-mode-square {
    @extend .box;
    position: relative;
    margin-bottom: 1.17rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -ms-flex-direction: column;
    overflow: hidden;
    .field-name-field-basic-image-image {
      margin: -1.17rem -1.17rem 1.17rem;
      //overflow: hidden;
    }
    .group-image-wrapper {
      position: relative;
    }
    .field-name-field-teaser-logo {
      position: absolute;
      bottom: 1.8rem;
      left: 0;
      max-width: 50%;
      height: auto;
      z-index: 1;
    }
    img {
      width: 100%;
      display: block;
      transition: transform .5s;
      transform: scale(1.01,1.01);
      position: relative;
    }
    h2 {
      margin: 0 0 0.3rem;
    }
    .field-name-node-link {
      text-align: right;
      align-self: flex-end;
      margin-top: auto;
    }
    &::before {
      background: $yellow;
      width: 100%;
      display: block;
      content: " ";
      height: 0.25rem;
      max-height: 0;
      transition: max-height 0.2s;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 4;
    }
    &:hover {
      &::before {
        max-height: 0.25rem;
      }
      img {
        transform: scale(1,1);
      }
    }
  }

  // List
  &.view-mode-search_result,
  &.view-mode-list {
    position: relative;
    .field-name-field-basic-image-image {
      margin: -0.58rem;
    }
    .col-content {
      padding-top: 0.29rem;
      padding-left: 1rem;
    }
  }

  // Full
  &.view-mode-full {
    .field {
      margin-bottom: 1rem;
    }
    .field-name-field-paragraphs {
      margin-bottom: 0;
      > div {
        margin-bottom: 1rem;
      }
      .field {
        margin-bottom: 0;
      }
    }
    .detail-header {
    }
    /*.field-name-smart-image {
      float: right;
      max-width:40%;
      margin-left: 1rem;
    }*/
    .field-name-smart-date {
      position: relative;
      padding-left: 30px;
      &::before {
        content: '\f133';
        font-family: FontAwesome;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5rem;
        font-size: 0.94rem;
        font-style: normal;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .field-name-detailed-info {
      position: relative;
      padding-left: 30px;
      &::before {
        content: '\f041';
        font-family: FontAwesome;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5rem;
        font-size: 0.94rem;
        font-style: normal;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .field-name-price {
      position: relative;
      padding-left: 30px;
      &::before {
        content: '\f0d6';
        font-family: FontAwesome;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5rem;
        font-size: 0.94rem;
        font-style: normal;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}

.view-nieuws {
  display: flex;
  flex-wrap: wrap;
  // Free panes
  .col-sm-4 .node {
    height: calc(100% - 30px);
  }
}