header {
  .header {
    position: relative;
  }
  /* Search form */
  .pane-pane-search {
    display: none;
    position: absolute;
    bottom: -60px;
    right: 0;
    left: 10px;
    height: 55px;
    padding: 10px;
    background: $darkblue;
    z-index: 10;
    border-radius: 2px 0 2px 2px;
    @include media-breakpoint-up(sm) {
      bottom: -35px;
      //right: 0;
      left: auto;
      background: #fff;
      border: 1px solid #c5d7dc;
      padding: 5px;
      height: 45px;
      border-radius: 0 0 2px 2px;
    }
    form {
      @extend .clearfix;
    }
    &.open {
      display: block;
    }
    .form-item {
      margin: 0;
      width: calc(100% - 32px);
      @include media-breakpoint-up(sm) {
        width: 200px;
      }
      float: left;
      height: 35px;
    }
    .form-actions {
      float: left;
      position: relative;
      margin: 0;
      width: 32px;
      height: 35px;
    }
    input.form-text {
      font-size: 14px;
      height: 20px;
      padding: 8px 9px 7px 13px !important;
      width: 100%;
      border: 0 none;
      border-radius: 2px 0 0 2px;
      background: #fff;
      color: $base-text-color;
      box-sizing: content-box;
      outline: none;
      min-height: 1px !important;
      vertical-align: top !important;
    }
    input[type="submit"] {
      height: 35px !important;
      width: 32px !important;
      text-indent: -10000px !important;
      overflow: hidden !important;
      outline: 0 none !important;
      background: #fff url(../images/icon-search.png) no-repeat center center !important;
      background-size: 16px auto !important;
      border-radius: 2px 2px !important;
      padding: 10px 5px !important;
      box-shadow: none !important;
      &:hover,
      &:focus {
        background-color: #fff;
        box-shadow: none;
      }
    }
  }
}

.view-mode-search_result,
.view-mode-list {
  margin-bottom: 1.17rem;
  background: $light;
  padding: 0.58rem;
  position: relative;
  h2 {
    &::after {
      content: none;
    }
  }
  h2,
  .meta > div {
    margin-bottom: 0.4rem !important;
  }
  .meta {
    font-style: italic;
  }
  .field-name-node-link {
    text-align: right;
    margin-top: 0.58rem;
  }
  .field-name-field-basic-image-image,
  .field-name-field-training-image {
    overflow: hidden;
  }
  img {
    width: 100%;
    display: block;
    transition: transform .5s;
    transform: scale(1.01,1.01);
    position: relative;
  }
  &::before {
    background: $lightblue;
    width: 100%;
    display: block;
    content: " ";
    height: 0.25rem;
    max-height: 0;
    transition: max-height 0.2s;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 4;
  }
  &:hover {
    &::before {
      max-height: 0.25rem;
    }
    img {
      transform: scale(1,1);
    }
  }

}

/* Search link in menu */
.pane-search {
  clear: right;
  float: right;
  margin-top: 15px;
}
.search-toggle {
  @include media-breakpoint-up(sm) {
    transition: all 0.2s;
  }
  overflow: hidden;
  position: absolute;
  top: 14px;
  right: 0;
  background: $darkblue;
  border-radius: 2px;
  color: #fff;
  width: 40px;
  height: 42px;
  display: block;
  padding: 0 0 0 40px;
  text-align: center;
  @include media-breakpoint-up(sm) {
    position: relative;
    color: $darkblue;
    background: transparent;
    border-radius: 0;
    width: 31px;
    height: 31px;
    display: inline-block;
    padding: 0 0 0 31px;
    top: auto;
    bottom: 0;
  }
  &:hover,
  &:focus {
    color: #fff;
    @include media-breakpoint-up(sm) {
      color: $lightblue;
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 42px;
    content: '\f002';
    display: block;
    font-family: FontAwesome;
    font-size: 0.8rem;
    line-height: 40px;
    text-align: center;
    font-weight: normal;
    @include media-breakpoint-up(sm) {
      width: 31px;
      height: 31px;
      line-height: 31px;
      padding-top: 4px;
    }
  }
  &.open {
    bottom: -10px;
    height: 52px;
    @include media-breakpoint-up(sm) {
      bottom: 0;
      height: 31px;
    }
    &::before {
      content: '\f00d';
    }
  }
}

.view-mode-searchpage {
  border-bottom: 1px solid $grey;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  h2 {
    &::after {
      content: none;
    }
  }
}