.pane-bundle-timeline {
  .row {
    display: flex;
    flex-wrap: wrap;
    .col-md-4 > .timeline-line {
      margin-top: 25px;
    }
  }
  .field-name-field-intro {
    font-style: italic;
    font-size: 1.05rem;
    line-height: 2rem;
    font-family: Lora, Georgia, "Times New Roman", Times, serif;
    margin-bottom: 40px;
  }
  .timeline {
    &.mobile-stacked,
    &.mobile-compact {
      @media only screen and (max-width: 768px) {
        .swiper-wrapper {
          display: block;
          .swiper-slide {
            width: 100% !important;
            margin-bottom: 20px;
          }
          .timeline-subtitle,
          .timeline-title,
          .timeline-text,
          img {
            padding-right: 20px;
          }
        }
        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }
    }
    &.mobile-compact {
      @media only screen and (max-width: 768px) {
        img {
          display: none;
        }
      }
    }
  }
  .timeline-line {
    border-top: 8px solid #E7E9E6;
    position: relative;
    &.yellow,
    &.light-blue,
    &.dark-blue,
    &.red {
      &:before {
        content: '';
        position: absolute;
        top: -24px;
        left: -10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 10px solid #ffffff;
      }
    }
    &.yellow:before {
      background-color: $yellow;
    }
    &.light-blue:before {
      background-color: $lightblue;
    }
    &.dark-blue:before {
      background-color: $darkblue;
    }
    &.red:before {
      background-color: $orange;
    }
    + .timeline-title {
      margin-top: 30px;
    }
  }
  .timeline-subtitle,
  .timeline-title,
  .timeline-text,
  img {
    padding-right: 50px;
  }
  .timeline-subtitle {
    margin-top: 20px;
    color: $lightblue;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
  }
  img {
    margin-top: 20px;
    margin-bottom: 20px;
    + .timeline-line {
      margin-top: 35px;
    }
  }
}

.panel-row.colored {
  &.grey {
    .pane-bundle-timeline .timeline-line {
      border-top: 8px solid darken(#E7E9E6,5%);
      &.yellow,
      &.light-blue,
      &.dark-blue,
      &.red {
        &:before {
          border: 10px solid $light;
        }
      }
    }
  }
  &.blue {
    .pane-bundle-timeline {
      .timeline-line {
        border-top: 8px solid darken(#a2dae4,10%);
        &.yellow,
        &.light-blue,
        &.dark-blue,
        &.red {
          &:before {
            border: 10px solid #a2dae4;
          }
        }
      }
      .timeline-subtitle {
        color: #ffffff;
      }
    }
  }
  &.orange {
    .pane-bundle-timeline {
      .timeline-line {
        border-top: 8px solid #ffffff;
        &.yellow,
        &.light-blue,
        &.dark-blue,
        &.red {
          &:before {
            border: 10px solid #F79276;
          }
        }
      }
      .timeline-subtitle {
        color: #ffffff;
      }
    }
  }
  &.yellow {
    .pane-bundle-timeline {
      .timeline-line {
        border-top: 8px solid darken(#F4BE23,10%);
        &.yellow,
        &.light-blue,
        &.dark-blue,
        &.red {
          &:before {
            border: 10px solid #F4BE23;
          }
        }
      }
      .timeline-subtitle {
        color: #185c71;
      }
    }
  }
  &.darkblue {
    .field-name-field-intro {
      color: #ffffff;
    }
    .pane-bundle-timeline {
      .timeline-line {
        border-top: 8px solid #ffffff;
        &.yellow,
        &.light-blue,
        &.dark-blue,
        &.red {
          &:before {
            border: 10px solid #295F70;
          }
        }
      }
      .timeline-title,
      .timeline-text,
      .timeline-subtitle {
        color: #ffffff;
      }
    }
  }
}

.timeline {
  .swiper-overflow .swiper-container {
    @media only screen and (max-width: 614px) {
      overflow-x: hidden;
    }
  }
}