#navbar-administration.navbar-oriented .navbar-tray-vertical {
  z-index: 9998 !important;
}

#navbar-administration.navbar-oriented .navbar-bar {
  z-index: 9999 !important;
}

body.navbar-fixed #navbar-administration .navbar-tray-horizontal {
  z-index: 9998 !important;
}