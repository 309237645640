body.panels-ipe {
  margin-bottom: 0 !important;
}
.page {
  padding-top: 75px;
  @include media-breakpoint-up(sm) {
    padding-top: 125px;
  }
  @include media-breakpoint-up(md) {
    padding-top: 155px;
  }
  @media only screen and (max-width: 768px) {
    overflow-x: hidden;
  }
}

.top-alert-visible .page {
  padding-top: 115px;
  @include media-breakpoint-up(sm) {
    padding-top: 165px;
  }
  @include media-breakpoint-up(md) {
    padding-top: 195px;
  }
  @media only screen and (max-width: 768px) {
    overflow-x: hidden;
  }
}

.pane-page-content {
  padding: 1.17rem 0;
  .rows {
    margin: -1.17rem 0;
  }
}

.panel-row {
  padding: 2.8rem 0 0;
  &:empty {
    padding: 0;
  }
  .panel-pane {
    margin-bottom: 2.8rem;
    &.pane-pane-page-title,
    &.pane-page-title {
      margin-bottom: 0;
      margin-top: -0.5rem;
    }
    &.pane-bundle-title {
      margin: 0;
      h2 {
        margin-bottom: 15px;
      }
    }
  }
  &.background {
    position: relative;
    .pane-title,
    .pane-bundle-title h2 {
      color: #fff;
      &::after {
        background-color: #fff;
      }
    }
  }
  &.colored {
    position: relative;
    &:after {
      top: 0;
      left: 50%;
      border-style: solid;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(255, 255, 255, 0);
      border-top-color: #ffffff;
      border-width: 20px;
      margin-left: -20px;
    }
    .quote {
      color: #fff;
      &::before {
        color: #fff;
      }
    }
    .pane-title,
    .pane-bundle-title h2 {
      color: #fff;
      &::after {
        background-color: #fff;
      }
    }
    .box {
      .pane-title,
      .pane-bundle-title h2 {
        color: $base-text-color;
        &::after {
          background-color: $lightblue;
        }
      }
    }
    .arrow-link {
      color: #fff;
    }
    .view-mode-search_result,
    .view-mode-list,
    .box {
      .arrow-link {
        color: $lightblue;
        &:hover,
        &:focus {
          color: $base-link-hover-color;
        }
      }
    }
    .pane-bundle-text.box {
      color: $base-text-color !important;
      .field-name-field-basic-text-text a {
        color: $lightblue !important;
        &:hover,
        &:focus {
          color: $base-link-hover-color !important;
        }
      }
    }
    ul.checks li::before {
      color: #fff;
    }
    /*.pane-bundle-automatic-list .field-name-field-link a,
    .pane-bundle-manual-list .field-name-field-link a {
      background-color: #fff;
      color: $lightblue;
      &:hover,
      &:focus {
        color: #fff;
      }
    }*/
    .pane-bundle-text {
      color: #fff;
      a {
        color: #fff;
      }
    }
    .pane-bundle-pane-form  {
      input.form-text,
      textarea,
      select {
        border-color: #fff;
      }
      .form-item {
        color: #fff;
      }
    }

  }
  &.blue {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a2dae4+0,86cdda+100 */
    background: rgb(162,218,228); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(162,218,228,1) 0%, rgba(134,205,218,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(162,218,228,1) 0%,rgba(134,205,218,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(162,218,228,1) 0%,rgba(134,205,218,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a2dae4', endColorstr='#86cdda',GradientType=0 ); /* IE6-9 */
    .button {
      background-color: #fff;
      color: $lightblue !important;
      &:hover,
      &:focus {
        color: #fff !important;
      }
    }
    .boxed {
      .button {
        background-color: $darkblue;
        color: #fff !important;
        &:hover,
        &:focus {
          color: #fff !important;
        }
      }
    }
    .view-mode-search_result,
    .view-mode-list {
      background-color: #fff;
      &::before {
        background-color: $darkblue;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      div {
        background-color: rgba(24,92,113, 0.8) !important;
        &:hover,
        &:focus {
          background-color: $lightblue !important;
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3) !important;
        }
      }
    }
  }
  &.darkblue {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2e6070+0,185c71+100 */
    background: #2e6070; /* Old browsers */
    background: -moz-linear-gradient(top, #2e6070 0%, #185c71 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #2e6070 0%,#185c71 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #2e6070 0%,#185c71 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2e6070', endColorstr='#185c71',GradientType=0 ); /* IE6-9 */
    .button {
      background-color: #fff;
      color: $lightblue !important;
      &:hover,
      &:focus {
        color: #fff !important;
      }
    }
    .boxed {
      .button {
        background-color: $darkblue;
        color: #fff !important;
        &:hover,
        &:focus {
          color: #fff !important;
        }
      }
    }
    .view-mode-search_result,
    .view-mode-list {
      background-color: #fff;
      &::before {
        background-color: $darkblue;
      }
    }
  }
  &.orange {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fb9d83+0,e66541+100 */
    background: rgb(251,157,131); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(251,157,131,1) 0%, rgba(230,101,65,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(251,157,131,1) 0%,rgba(230,101,65,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(251,157,131,1) 0%,rgba(230,101,65,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fb9d83', endColorstr='#e66541',GradientType=0 ); /* IE6-9 */
    .view-mode-search_result,
    .view-mode-list {
      background-color: #fff;
    }
  }
  &.grey {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c9d7db+0,c5d7dc+100 */
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f3f4f0+0,e6e8e5+100 */
    background: $light; /* Old browsers */
    //background: -moz-linear-gradient(top, #f3f4f0 0%, #e6e8e5 100%); /* FF3.6-15 */
    //background: -webkit-linear-gradient(top, #f3f4f0 0%,#e6e8e5 100%); /* Chrome10-25,Safari5.1-6 */
    //background: linear-gradient(to bottom, #f3f4f0 0%,#e6e8e5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3f4f0', endColorstr='#e6e8e5',GradientType=0 ); /* IE6-9 */
    .view-mode-search_result,
    .view-mode-list {
      background-color: #fff;
    }
    color: $darkblue;
    .quote {
      color: $darkblue;
      &::before {
        color: $darkblue;
      }
    }
    .pane-title,
    .pane-bundle-title h2 {
      color: $darkblue;
      &::after {
        background-color: $darkblue;
      }
    }
    .box {
      .pane-title,
      .pane-bundle-title h2 {
        color: $darkblue;
        &::after {
          background-color: $darkblue;
        }
      }
    }
    .arrow-link {
      color: $darkblue;
    }
    .view-mode-search_result,
    .view-mode-list,
    .box {
      .arrow-link {
        color: $darkblue;
        &:hover,
        &:focus {
          color: $darkblue;
        }
      }
    }
    .pane-bundle-text.box {
      color: $darkblue !important;
      .field-name-field-basic-text-text a {
        color: $darkblue !important;
        &:hover,
        &:focus {
          color: $darkblue !important;
        }
      }
    }
    ul.checks li::before {
      color: $darkblue;
    }
    .pane-bundle-text {
      color: $darkblue;
      a {
        color: $darkblue;
      }
    }
    .pane-bundle-pane-form  {
      input.form-text,
      textarea,
      select {
        border-color: $darkblue;
        &.error {
          border-color: red;
        }
      }
      .form-item {
        color: $darkblue;
      }
    }
    .button {
      background-color: $darkblue;
      color: #fff !important;
      &:hover,
      &:focus {
        color: #fff !important;
        background-color: $lightblue;
      }
    }
  }
  &.yellow {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f6c027+0,e7ab01+100 */
    background: rgb(246,192,39); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(246,192,39,1) 0%, rgba(231,171,1,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(246,192,39,1) 0%,rgba(231,171,1,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(246,192,39,1) 0%,rgba(231,171,1,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6c027', endColorstr='#e7ab01',GradientType=0 ); /* IE6-9 */
    .button {
      background-color: $darkblue;
      color: #fff !important;
      &:hover,
      &:focus {
        color: #fff !important;
        background-color: $lightblue;
      }
    }
  }
}

aside .panel-pane {
  margin-bottom: 30px;
  h2 {
    &::after {
      display: none;
    }
  }
}
.panel-row.colored + .panel-row {
  padding-top: 2.8rem;
  &::after {
    content: none;
  }
}

/* Panes */
.pane-bundle-manual-list,
.pane-bundle-automatic-list {
  .field-name-field-link {
    text-align: center;
  }
  .boxed {
    .box {
      padding: 0 0 3rem;
    }
    .field-name-field-link {
      margin-top: -1.3rem;
      position: relative;
      z-index: 5;
    }
    &.columns {
      .box {
        padding: 0;
      }
      .field-name-field-link {
        margin-top: 1.3rem;
      }
    }
  }

  .stairway {
    @include media-breakpoint-up(sm) {
      .col-sm-4:nth-child(3n + 2) .view-mode-square {
        margin-top: 1.5rem;
      }
      .col-sm-4:nth-child(3n + 3) .view-mode-square {
        margin-top: 3rem;
      }
      .col-sm-3:nth-child(4n + 2) .view-mode-square {
        margin-top: 1rem;
      }
      .col-sm-3:nth-child(4n + 3) .view-mode-square {
        margin-top: 2rem;
      }
      .col-sm-3:nth-child(4n + 4) .view-mode-square {
        margin-top: 3rem;
      }
    }
  }
}

/* Jump links */
.panel-row {
  //overflow: hidden;
}
.rows {
  overflow: hidden;
}

.pane-bundle-jump-links {
  .fieldable-panels-pane {
    margin-top: -1.12em;
    border-bottom: 1px solid $lightblue;
    float: none;
    background: $lightblue;
    margin-left: -5000px;
    margin-right: -5000px;
    padding-left: calc(5000px - 10px);
    padding-right: calc(5000px - 10px);
    width: calc(100% + 10000px) !important;
    @include media-breakpoint-up(sm) {
      padding-left: calc(5000px - 20px);
      padding-right: calc(5000px - 20px);
    }
    @include media-breakpoint-up(md) {
      padding-left: calc(5000px - 30px);
      padding-right: calc(5000px - 30px);
    }
    @include media-breakpoint-up(lg) {
      padding-left: calc(5000px - 40px);
      padding-right: calc(5000px - 40px);
    }
  }
  ul {
    margin: 0;
    list-style: none;
    white-space: nowrap;
  }
  li {
    display: inline-block;
    a {
      display: block;
      padding: 10px 0.7rem;
      font-size: 0.76rem;
      text-transform: uppercase;
      font-weight: bold;
      color: #fff;
      text-align: center;
      @include media-breakpoint-up(sm) {
        padding: 20px;
      }
      &:hover,
      &:focus {
        color: #fff;
        background-color: $darkblue;
      }
    }
  }
  .field-name-jump-links {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    @include media-breakpoint-up(sm) {
      padding: 0 20px;
    }
    @include media-breakpoint-up(md) {
      padding: 0 30px;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 40px;
    }
  }
  .field-name-jump-links.sticky {
    background: $lightblue;
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 9;
    -webkit-box-shadow: 0 0 8px rgba(0,0,0,0.3);
    -moz-box-shadow: 0 0 8px rgba(0,0,0,0.3);
    box-shadow: 0 0 8px rgba(0,0,0,0.3);
    @include media-breakpoint-up(sm) {
      top: 87px;
    }
    @include media-breakpoint-up(md) {
      top: 107px;
    }
    @include media-breakpoint-up(lg) {
      top: 107px;
    }
  }
}

.navbar-administration.navbar-tray-open.navbar-horizontal .pane-bundle-jump-links .field-name-jump-links.sticky {
  top: 187px;
}
.navbar-administration.navbar-vertical .pane-bundle-jump-links .field-name-jump-links.sticky,
.navbar-administration.navbar-horizontal .pane-bundle-jump-links .field-name-jump-links.sticky {
  top: 147px;
}

.banner + .content .pane-bundle-jump-links .fieldable-panels-pane {
  margin-top: -2.8rem;
}

.field-name-ds-function-gallery-popup {
  @extend .clearfix;
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
  .gallery-item {
    //width: 25%;
    //float: left;
    padding: 0 5px;
    position: relative;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    -ms-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
    @include media-breakpoint-up(sm) {
      padding: 0 0.7rem;
    }
    &:focus,
    &:hover {
      opacity: 0.8;
    }
    .more {
      background: rgba(0,0,0, 0.3);
      opacity: 0.7;
      position: absolute;
      left: 5px;
      right: 5px;
      top: 0;
      bottom: 0;
      color: #fff;
      text-align: center;
      -webkit-transition: opacity 0.2s;
      -moz-transition: opacity 0.2s;
      -ms-transition: opacity 0.2s;
      -o-transition: opacity 0.2s;
      transition: opacity 0.2s;
      &::before {
        content: "+";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        text-align: center;
        width: 100%;
        font-size: 40px;
        font-weight: 100;
      }
      &:hover,
      &:focus {
        opacity:0.5;
      }
    }
  }
}

.pane-title {
  font-size: 1.3rem;
  line-height: 2rem;
  margin: 0 0 1rem;
}

.pane-bundle-free-block {
  @extend .box;
  position: relative;
  .field-name-field-label {
    position: absolute;
    top: -30px;
    right: 6px;
    background: #F5C85C;
    text-transform: uppercase;
    font-weight: bold;
    padding: 1px 8px;
    color: white;
    font-size: 0.65rem;
  }
  .fieldable-panels-pane {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    width: 100%;
    .flipper {
      cursor: pointer;
      -webkit-transition: all .5s cubic-bezier(.25,.46,.45,.94);
      transition: all .5s cubic-bezier(.25,.46,.45,.94);
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      &.hover {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
        .back {
          z-index: 3;
          -webkit-backface-visibility: visible;
          backface-visibility: visible;
          opacity: 1;
        }
      }
      .front,
      .back {
        -webkit-transition: all .5s cubic-bezier(.25,.46,.45,.94);
        transition: all .5s cubic-bezier(.25,.46,.45,.94);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        top: 0;
        left: 0;
        position: absolute;
        display: block;
        width: 100%;
      }
      .front {
        z-index: 2;
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
      }
      .back {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
        opacity: 0;
      }
    }
  }
  .field-name-field-basic-image-image {
    margin: -1.17rem -1.17rem 1.17rem;
    overflow: hidden;
  }
  .pane-title,
  .pane-bundle-title h2 {
    color: $darkblue;
  }
  img {
    width: 100%;
    display: block;
    -moz-transition: transform .5s;
    -webkit-transition: transform .5s;
    transition: transform .5s;
    transform: scale(1.01,1.01);
    position: relative;
  }
  .col-left {
    padding-right: 0;
    .field-name-field-basic-image-image {
      margin: 0;
    }
  }
  h2 {
    margin: 0 0 0.3rem;
    a {
      color: $darkblue;
    }
  }
  .field-name-field-link {
    text-align: right;
    align-self: flex-end;
    margin-top: auto;
  }
  > div[onclick] .hover {
    background: $yellow;
    width: calc(100% + 2.34rem);
    display: block;
    height: 0.25rem;
    max-height: 0;
    -webkit-transition: max-height 0.2s;
    -moz-transition: max-height 0.2s;
    -ms-transition: max-height 0.2s;
    -o-transition: max-height 0.2s;
    transition: max-height 0.2s;
    position: absolute;
    left: -1.17rem;
    bottom: -1.17rem;
    z-index: 4;
  }
  > div[onclick]:hover {
    .hover {
      max-height: 0.25rem;
    }
    img {
      transform: scale(1,1);
    }
  }
  &.lightblue {
    background: $lightblue;
    a {
      color: $darkblue;
    }
    h2::after {
      background-color: $darkblue;
    }
    div[onclick] .hover {
      background-color: $darkblue;
    }
  }
  &.grey {
    background: $light;
    a {
      color: $darkblue;
    }
    h2::after {
      background-color: $darkblue;
    }
    div[onclick] .hover {
      background-color: $darkblue;
    }
  }
  &.yellow {
    background: $yellow;

    h2::after {
      background-color: $darkblue;
    }
    a {
      color: $darkblue;
    }
    div[onclick] .hover {
      background-color: $darkblue;
    }
  }
  &.pink,
  &.red {
    background: $orange;
    color: #fff;

    h2::after {
      background-color: #fff;
    }
    a {
      color: #fff;
    }
    div[onclick] .hover {
      background-color: $darkblue;
    }
  }
  &.darkblue {
    background: $darkblue;
    color: #fff;

    h2::after {
      background-color: #fff;
    }
    &.pane-bundle-free-block .pane-title,
    &.pane-bundle-free-block .pane-bundle-title h2 {
      color: #fff;
    }
    a {
      color: #fff;
    }
    div[onclick] .hover {
      background-color: $lightblue;
    }
  }
  /*.back {
    display: none;
  }*/
}

.pane-bundle-title {
  .large h2 {
    font-size: 1.8rem;
    line-height: 2.4rem;
    text-transform: uppercase;
    margin: 0 0 1.2rem 0;
    font-weight: 800;
  }
}

.pane-bundle-text,
.field-name-body {
  .box {
    margin-bottom: 1rem;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
.pane-bundle-text {
  .field-name-field-link {
    text-align: center;
    margin-bottom: -2.8rem;
    a {
      margin-top: 1rem;
    }
  }
  &.box {
    margin-bottom: 3rem;
    height: calc(100% - 3rem) !important;
    .fieldable-panels-pane {
      display: flex;
      height: 100%;
      flex-direction: column;
    }
    .field-name-field-link {
      margin-top: auto;
    }
  }
}

.pane-bundle-logo-link .fieldable-panels-pane,
.pane-bundle-logo-s .fieldable-panels-pane {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  ms-flex-wrap: wrap;
  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
    ms-flex-wrap: nowrap;
  }
  .img {
    width: 25%;
    padding: 10px;
    max-width: 195px;
    @include media-breakpoint-up(sm) {
      width: 20%;
    }
    @include media-breakpoint-up(md) {
      width: auto;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    max-height: 175px;
  }
}

/* Equal height columns */
.panel-row {
  @include media-breakpoint-up(sm) {
    > .container > .row,
    &.row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      > .panels-ipe-region {
        width: 100%;
      }
      > .col-md-12 {
        width: 100%;
      }
      &:before,
      &:after {
        content: normal; // IE doesn't support `initial`
      }
    }
  }

  // Free panes
  .col-sm-3 .panel-pane,
  .col-sm-4 .panel-pane,
  .col-sm-6 .panel-pane,
  .col-sm-8 .panel-pane {
    height: calc(100% - 30px);
    &.pane-bundle-free-block > div,
    &.pane-bundle-free-block > div > .box,
    &.pane-bundle-promo-block > div,
    &.pane-bundle-promo-block > div > .box {
      height: 100%;
    }
  }

  // Lists
  .field-name-automatic-list,
  .field-name-manual-list {
    @include media-breakpoint-up(xs) {
      > .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        .col-sm-6 {
          float: left;
          width: 50%;
        }
        .node-project,
        .node-nieuws,
        .node-panopoly-landing-page {
          height: calc(100% - 1.17rem);
        }
        &:before,
        &:after {
          content: normal; // IE doesn't support `initial`
        }
      }
    }
  }

  // No equal height
  &.no-equal-height {
    @include media-breakpoint-up(sm) {
      > .container > .row,
      &.row {
        display: block;
      }
    }
    // Free panes
    .col-sm-3 .panel-pane,
    .col-sm-4 .panel-pane,
    .col-sm-6 .panel-pane,
    .col-sm-8 .panel-pane {
      height: auto;
      &.pane-bundle-free-block > div,
      &.pane-bundle-free-block > div > .box,
      &.pane-bundle-promo-block > div,
      &.pane-bundle-promo-block > div > .box {
        height: auto;
      }
    }

    // Lists
    .field-name-automatic-list,
    .field-name-manual-list {
      > .row {
        display: block;
        .node-project,
        .node-nieuws,
        .node-panopoly-landing-page {
          height: auto;
        }
      }
    }
  }
}

.pane-bundle-video {
  iframe {
    width: 100%;
  }
  .field-name-field-short-description {
    margin-top: 0.3rem;
    font-style: italic;
    font-size: 0.9rem;
  }
}

.pane-bundle-slogan {
  padding-top: 2em;
  padding-bottom: 2em;
  .field-name-field-slogan {
    font-size: 2rem;
    line-height: 3rem;
    @include media-breakpoint-up(sm) {
      font-size: 2.8rem;
      line-height: 3.4rem;
    }
    text-transform: uppercase;
    margin: 0 0 1.2rem 0;
    font-weight: bold;
    color: #fff;
    max-width: 800px;
    text-shadow: 4px 4px 20px rgba(0,0,0,0.38);
  }
  .field-name-field-slogan-link .button {
    background-color: $darkblue;
    color: #fff !important;
    &:hover,
    &:focus {
      color: #fff !important;
      background-color: $lightblue;
    }
  }
}

/* Default view mode styles */
.view-mode-square {
  h2 {
    &::after {
      content: none;
    }
  }
  div.field {
    margin-bottom: 0;
  }
}

.view-mode-title[onclick] {
  &::before {
    background: $darkblue;
    width: 100%;
    display: block;
    content: " ";
    height: 0.15rem;
    max-height: 0;
    -webkit-transition: max-height 0.2s;
    -moz-transition: max-height 0.2s;
    -ms-transition: max-height 0.2s;
    -o-transition: max-height 0.2s;
    transition: max-height 0.2s;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 4;
  }
  &:hover {
    &::before {
      max-height: 0.15rem;
    }
  }
}

/* block in wysiwyg */
div.image-group {
  @extend .clearfix;
  .media:last-child {
    margin-right: 0;
  }
}

/* video as background */
.video-background {
  position: relative;
  min-height: 400px;
  padding: 4.8rem 0 0;
}
.background-video {
  position: absolute;
  z-index: 0;
  object-fit: cover; // this is the key
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &::-webkit-media-controls {
    display:none !important;
  }
}

/* paragraphs */
.paragraphs-item-embed {
  margin: 25px 0 30px 0;
}
.paragraphs-item-quote {
  background: url(../images/quote-marks.svg) no-repeat top 9px left;
  padding-top: 43px;
  @include media-breakpoint-up(sm) {
    padding-left: 47px;
    padding-top: 0;
  }
  .field-name-field-quote {
    font-style: italic;
    font-size: 1.05rem;
    line-height: 2rem;
    font-family: Lora, Georgia, "Times New Roman", Times, serif;
  }
  .group-quotee {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 15px;
  }
  .field-name-field-quote-image {
    flex: 1 0 64px;
    max-width: 64px;
    img {
      border-radius: 50%;
      width: 50px;
      height: 50px;
    }
  }
  .field-name-field-quotee {
    flex: 1 0 calc(100% - 64px);
    max-width: calc(100% - 64px);
  }
}

.paragraphs-item-text {
  &.colored {
    padding: 1.17rem 1.17rem 0.17rem;
    margin-bottom: 1rem;
  }
  &.white {
    @extend .box;
  }
  &.grey {
    background: $light;
    a {
      color: $darkblue;
    }
  }
  &.yellow {
    background: $yellow;
    a {
      color: $darkblue;
    }
  }
  &.red {
    background: $orange;
    color: #fff;
    a {
      color: #fff;
    }
  }
  &.darkblue {
    background: $darkblue;
    color: #fff;
    a {
      color: #fff;
    }
  }
  &.lightblue {
    background: $lightblue;
    a {
      color: $darkblue;
    }
  }
}

