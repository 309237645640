.pane-bundle-newsletter {
  text-align: center;
  h2.pane-title {
    color: $orange;
    font-size: 1.17rem;
    margin: 1rem 0 0.5rem;
    &::after {
      content: none;
    }
  }
  form {
    width: 450px;
    max-width: 100%;
    display: inline-block;
    margin-bottom: 1rem;
    text-align: left;
    .form-type-textfield {
      //width: calc(100% - 160px);
      //float: left;
      margin: 0 0 1em;
      max-width: 400px;
      > label {
        top: 12px;
      }

      .form-text {
        padding: 10px;
        margin: 0;
      }
      &.form-item-not-empty,
      &.form-item-focus {
        label {
          display: none;
        }
        input.form-text {
          padding: 10px;
        }
      }
    }
    .captcha {
      margin: 1em 0;
    }
    .button {
      //float: right;
      width: 150px;
      padding: 11px;
    }
    .form-checkboxes {
      clear: both;
      padding: 0.3em 0;
      text-align: left;
      .form-item {
        margin: 0.5em 0;
      }
      label.option {
        text-transform: none;
        font-weight: normal;
        font-size: $base-font-size;
      }
    }
    .form-checkboxes + .button {
      float: left;
    }
  }

}

/* Newsletter popup */
.newsletter-modal {
  background: #fff;
  padding: 20px 30px;
  text-align: left;
  max-width: 650px;
  margin: 40px auto;
  position: relative;
  .mfp-close{
    font-size: 30px;
    box-shadow: none;
    &:hover,
    &:focus {
      color: $darkblue;
      background-color: transparent;
      box-shadow: none;
    }
  }

  .form-required {
    display: none;
  }
  label {
    font-weight: normal;
  }
}
.my-mfp-zoom-in .newsletter-modal {
  opacity: 0;
  transform: translateY(-50px);
  transition: all 0.3s ease-out 0s;
}
.my-mfp-zoom-in.mfp-ready .newsletter-modal {
  opacity: 1;
  transform: translateY(0px);
}
.footer-invisible {
  display: none;
}
.website_naam-textfield {
  display: none;
}