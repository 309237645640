.breadcrumb {
  font-size: 0.8rem;
  margin-top: 10px;
  a {
    text-decoration: none;
    color: $darkblue;
    &:hover,
    &:focus {
      color: $base-link-hover-color;
    }
    &:active {
      color: $base-link-active-color;
    }
  }
}