html#ecwid_html body#ecwid_body .page > .content {
  a,
  a:visited,
  a:hover,
  a:focus,
  a:active,
  div.ecwid-productBrowser-details-descr a,
  div.ecwid-productBrowser-details-descr a:hover,
  div.ecwid-productBrowser-details-descr a:active,
  div.ecwid-productBrowser-details-descr a:visited,
  .ecwid div.ecwid-productBrowser-cart-termsCheckbox a,
  .ecwid div.ecwid-productBrowser-cart-termsCheckbox a:active,
  .ecwid div.ecwid-productBrowser-cart-termsCheckbox a:visited
  {
    font-size: 1rem;
  }
  /* Header */
  .ecwid-results-topPanel,
  .ecwid-productBrowser-head {
    @extend .clearfix;
    background: #f3fafb !important;
    padding: 0 1rem 1rem !important;
    div {
      font-size: $base-font-size !important;
    }
  }
  //.ecwid-productBrowser-head,
  .ecwid-productBrowser-auth {
    display: none !important;
  }
  div.ecwid-productBrowser {
    padding: 0 !important;
  }
  div.ecwid-productBrowser-category {
    padding-top: 0 !important;
  }
  .ecwid-results-topPanel-itemsCountLabel-cell {
    float: left !important;
  }
  .ecwid-productBrowser-productNameLink {
    font-weight: bold;
    font-size: 1rem;
    a {
      font-weight: bold !important;
      font-size: 1rem !important;
    }
  }
  .ecwid-productBrowser-price-value {
    font-size: 1rem;
  }
  .ecwid .ecwid-btn--primary {
    border-radius: 0;
    text-transform: uppercase;
    font-size: 0.75rem;
    padding: 15px 35px;
    font-weight: bold;
    box-shadow: 0px 8px 20px rgba(0,0,0,0.13);
    color: #fff;
    background-color: $lightblue;
    &:hover,
    &:focus {
      color: #fff;
      background-color: $darkblue;
    }
  }
  /* Pager */
  .ecwid-pager {
    font-size: 0;
    margin-top: 1rem;
    border-top: 0;

    .ecwid-pager-link {
      margin: 0 2px;
      padding: 0;
      display: inline-block;
      *zoom: 1;
      *display: inline;
      font-size: 0.94rem;
      line-height: 0.94rem;
      &.ecwid-pager-next-label {
        margin-right: 0;
      }
      &.ecwid-pager-prev-label {
        margin-left: 0;
      }
      .ecwid-pager-link-disabled span {
        padding: 0.47rem 0.7rem;
      }

      a {
        padding: 0.47rem 0.7rem;
        display: inline-block;
        *zoom: 1;
        *display: inline;
        background: $light;
        color: $darkblue;
        text-decoration: none !important;
        -moz-transition: all 0.1s ease;
        -webkit-transition: all 0.1s ease;
        transition: all 0.1s ease;
        span {
          text-decoration: none !important;
        }
        &:hover,
        &:focus {
          background-color: $lightblue;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }

  .webshop-disclaimer {
    font-size: 0.8rem;
    text-align: center;
    margin-top: 2rem;
    img {
      display: inline-block;
    }
  }

  div[id*=my-search-] {
    background: #f3fafb;
    padding: 1rem;
  }

  div[id*=my-cart-] {
    margin: 1rem 0;
    @include media-breakpoint-up(sm) {
      margin: 0;
    }
  }

  /* Cart */
  .ecwid-minicart {
    display: none;
    background: $darkblue;
    border-radius: 0 0 0 2px;
    border: 0;
    color: #fff;
    @include media-breakpoint-up(sm) {
      display: block;
      position: fixed;
      top: 146px;
      right: 0;
      transition: all 0.2s;
    }
    padding: 5px;
    width: 250px;
    height: 75px;
    box-shadow: 0px 8px 20px rgba(0,0,0,0.13);

    div.ecwid-minicart-label {
      width: auto;
      line-height: 0.9rem;
      top: 13px;
      left: 60px;
      text-align: left;
    }
    .ecwid-minicart-caption {
      display: none;
    }
    .ecwid-minicart-link {
      margin-top: 0.5em;
      text-decoration: underline;
      top: 5px;
      left: 60px;
      text-align: left;
    }
    .ecwid-minicart-clickArea {
      top: 3px;
      left: 9px;
    }
    .ecwid-minicart-counter {
      left: 12px;
      top: 19px;
    }
    * {
      color: #fff;
      font-size: 0.8rem;
    }
  }
  div.ecwid-minicart-dropTargetEngage span.ecwid-minicart-label-text {
    background-color: transparent;
  }
  div.ecwid-minicart-dropTargetEngage {
    background: $lightblue;
  }

  /* Sidebar */
  .ecwid-categories-vertical table {
    width: 100%;
  }
  .ecwid-categories-vertical-table-cell {
    border-bottom: 1px solid $grey;
  }
  td.ecwid-categories-vertical-table-cell table.ecwid-categories-vertical-table-cell-categoryLink {
    margin: 5px;
  }
  span.ecwid-categories-category {
    color: $darkblue;
    display: block;
    font-size: 1rem;
    line-height: $base-line-height;
    text-decoration: none;
    &:hover,
    &:focus {
      color: $lightblue;
    }
    ul {
    }
  }
  div.ecwid-categories-vertical td.gwt-MenuItem {
    padding: 0 10px;
    font-size: 1rem;
  }
  div.ecwid-categoriesMenuBar span.ecwid-categories-category {
    font-size: 1rem;
  }

  /* Detail */
  div.ecwid-productBrowser-gallery {
    margin-bottom: 1rem;
    text-align: center;
    .ecwid-productBrowser-gallery-image img {
      max-width: 400px;
    }
  }
  .ecwid div {
    font-size: 1rem;
  }

  /* Shopping cart */
  div.ecwid-productBrowser-productNameLink a,
  div.ecwid-productBrowser-productNameLink a:active,
  div.ecwid-productBrowser-productNameLink a:visited {
    text-decoration: none;
  }
}


.node-webshop-product.view-mode-list {
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,0.2);
  .field {
    margin-bottom: 0.3rem;
  }
  .field-name-field-basic-image-image {
    margin: - 0.58rem -0.58rem 0.58rem;
    @include media-breakpoint-up(sm) {
      margin: - 0.58rem;
    }
  }
  .row {
    @include media-breakpoint-up(xs) {
      display: flex;
    }
  }
  .col-image {
    @include media-breakpoint-up(xs) {
      display: flex;
    }
    .background {
      width: calc(100% + 1.16rem);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      margin: -0.58rem  -0.58rem 0.58rem;
      @include media-breakpoint-up(xs) {
        margin: -0.58rem;
      }
      .ratio {
        padding: 0 0 100%;
        height: 0;
        width: 100%;
      }
    }
  }
  .col-content {
    padding-top: 0.29rem;
    padding-left: 1rem;
  }
  h3,
  h2 {
    margin: 0 0 0.9rem 0;
    font-size: 1rem;
    line-height: 1.3rem;
    text-transform: uppercase;
    font-weight: bold;
  }
  .field-name-field-price-value {
    color: $orange;
    font-weight: bold;
    text-transform: uppercase;
  }
  .field-name-field-short-description {
    font-size: 0.86rem;
    font-style: italic;
  }
  .field-name-field-link {
    text-align: right;
  }
}

html#ecwid_html body#ecwid_body.scrolling .page .ecwid-minicart {
  @include media-breakpoint-up(sm) {
    position: fixed;
    top: 107px;
    right: 0;
  }
}