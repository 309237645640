.faq-box {
  @extend .box;
  position: relative;
  .field-name-field-basic-image-image {
    margin: -1.17rem -1.17rem 1.17rem;
    overflow: hidden;
  }
  img {
    width: 100%;
    display: block;
    transition: transform .5s;
    transform: scale(1.01,1.01);
    position: relative;
  }
  h2 {
    margin: 0 0 0.3rem;
    &::after {
      content: none;
    }
  }
  ul {
    list-style: none;
    margin: 0;
  }
  li {
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #c5d7dc;
    padding-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}

.node-faq.view-mode-full {
  max-width: 900px;
}

.node-faq.view-mode-search_result {
  display: flex;
  flex-direction: column;
  .group-image-wrapper {
    border-bottom: 3px solid $yellow;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
  .field-name-field-short-description {
    margin-bottom: 15px;
  }
  .field-name-node-link {
    margin-top: auto;
  }
}



.node-type-faq {
  aside {
    @include media-breakpoint-up(sm) {
      margin-top: 30px;
    }
  }
}


/* related questions */

.pane-pane-related-faq {
  .item-list {
    ul {
      list-style-type: none;
      margin: 0;
    }
    li {
      border-bottom: 1px solid #c5d7dc;
      margin: 0;
      padding: 0;
      &:first-child {
        border-top: 1px solid #c5d7dc;
      }
      a {
        padding: 5px 0;
        color: #185c71;
        display: block;
      }
    }
  }
}