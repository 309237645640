.node-partner {

  // List
  &.view-mode-search_result,
  &.view-mode-list {
    position: relative;
    padding: 0;
    background: transparent;
    &::before {
      content: none;
    }
    .field-name-field-link {
      text-align: left;
    }
    h3,
    p {
      margin: 0;
    }
  }

  &.view-mode-list {
    .row {
      @include media-breakpoint-up(xs) {
        display: flex;
      }
    }
    .col-image {
      @include media-breakpoint-up(xs) {
        display: flex;
      }
      .background {
        width: calc(100% + 1.16rem);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        .ratio {
          padding: 0 0 100%;
          height: 0;
          width: 100%;
        }
      }
    }
    .col-content {
      padding-top: 0.29rem;
      padding-left: 1rem;
    }

    background-color: $darkblue;
    color: #ffffff;
    max-width: 1070px;
    h3 {
      margin-top: 15px;
      margin-bottom: 2px;
    }
    p {
      margin-bottom: 15px;
      font-size: 17px;
      line-height: 23px;
    }
    h3,
    p,
    .field-name-node-link {
      padding-left: 15px;
      padding-right: 15px;
      @include media-breakpoint-up(md) {
        padding-left: 0;
      }
    }
    .field-name-node-link a {
      color: #fff;
    }
  }

  // Title
  &.view-mode-title {
    position: relative;
    border-bottom: 1px solid #185c71;
    padding: 0.8rem;
    background: #fff;
    margin: 0;
    &:last-child {
      border-bottom: 0 none;
    }
    .field-name-field-link {
      text-align: left;
      margin: 0 !important;
    }
    h3,
    p {
      margin: 0;
    }
  }

  // Square
  &.view-mode-square {
    //@extend .box;
    position: relative;
    margin-top: 15px;
    margin-bottom: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    .col-md-7 {
      padding-top: 15px;
      @include media-breakpoint-up(md) {
        padding-top: 0;
        padding-right: 25px;
      }
    }
    .field-name-field-basic-image-image {
      //margin: -1.17rem -1.17rem 1.17rem;
      overflow: hidden;
    }
    img {
      width: 100%;
      display: block;
      transition: transform .5s;
      transform: scale(1.01, 1.01);
      position: relative;
    }
    h2 {
      margin: 0 0 0.3rem;
    }
    .field-name-field-link {
      text-align: right;
      align-self: flex-end;
      margin-top: auto;
    }
    &::before {
      background: $yellow;
      width: 100%;
      display: block;
      content: " ";
      height: 0.25rem;
      max-height: 0;
      transition: max-height 0.2s;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 4;
    }
    &:hover {
      /*&::before {
        max-height: 0.25rem;
      }*/
      img {
        transform: scale(1, 1);
      }
    }
  }
}

/*.field-name-automatic-list {
  .node-partner.view-mode-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -20px;
  }
}*/