.top-alert {
  background-color: $darkblue;
  color: #fff;
  padding: 6px 0;
  display: none;
  .container {
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    flex-wrap: wrap;
  }
  p {
    margin: 0;
    flex: 1 0 calc(100% - 60px);
    width: calc(100% - 60px);
    text-align: center;
  }
  .top-alert-close {
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    background: transparent url(../images/close-white.svg) no-repeat top 5px right 0;
    height: 18px;
    text-indent: -9999px;
    overflow: hidden;
    font-size: 0;
    flex: 1 0 60px;
    width: 60px;
    text-align: right;
  }
}