header {
  padding: 0;
  > .container {
    padding-bottom: 10px;
    @include media-breakpoint-up(sm) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    @include media-breakpoint-up(md) {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
  border-bottom: 1px solid #c5d7dc;
  margin-bottom: 0.5rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 800;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;

  .logo-wrapper {
    margin-top: 10px;
    display: block;
    width: 120px;
    height: 50px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    @include media-breakpoint-up(sm) {
      width: 155px;
      height: 65px;
    }
    @include media-breakpoint-up(md) {
      width: 200px;
      height: 81px;
      margin-top: 5px;
    }
  }
  .logo-wrapper > .logo {
    display: block;
    background: transparent url(../logo.png) no-repeat center center;
    background-size: 100% auto;
    overflow: hidden;
    text-indent: -10000px;
    height: 100%;
  }
  .pane-pane-header {
    float: left;
  }
  .social-icons {
    display: none;
    float: right;
    @include media-breakpoint-up(sm) {
      display: block;
    }

    p {
      margin: 0;
      height: 33px;
      overflow: hidden;
    }
  }
  .mobile-social-icons {
    margin: 0 5px;
  }
  .social-icons,
  .mobile-social-icons {
    .icon {
      width: 33px;
      height: 33px;
      padding-left: 30px;
      overflow: hidden;
      position: relative;
      display: inline-block;
      text-align: center;
      span {
        text-indent: -9999px;
        overflow: hidden;
        display: inline-block;
      }
      &:hover,
      &:focus {
        &::before {
          color: $lightblue;
        }
      }
      &::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 33px;
        line-height: 28px;
        content: '\f09a';
        font-family: FontAwesome;
        color: $darkblue;
        padding: 0.17rem;
      }
      &.soundcloud {
        width: 44px;
      }
      &.twitter::before {
        content: '\f099';
      }
      &.instagram::before {
        content: '\f16d';
      }
      &.youtube::before {
        content: '\f167';
      }
      &.soundcloud::before {
        content: '\f1be';
      }
    }
  }
}

.navbar-administration.navbar-vertical,
.navbar-administration.navbar-horizontal {
  header {
    top: 40px;
  }
}
.navbar-administration.navbar-tray-open.navbar-horizontal {
  header {
    top: 80px;
  }
}


.scrolling header {
  -webkit-box-shadow: 0 0 8px rgba(0,0,0,0.3);
  -moz-box-shadow: 0 0 8px rgba(0,0,0,0.3);
  box-shadow: 0 0 8px rgba(0,0,0,0.3);
  &.no-shadow {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  > .container {
    @include media-breakpoint-up(sm) {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    @include media-breakpoint-up(md) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .logo-wrapper {
    @include media-breakpoint-up(sm) {
      width: 135px;
      height: 55px;
    }
    @include media-breakpoint-up(md) {
      width: 160px;
      height: 70px;
      margin-top: 7px;
    }
  }
  .header .main-menu {
    margin-top: 5px;
  }
  .search-toggle {
    @include media-breakpoint-up(sm) {
      bottom: 10px;
    }
  }
}