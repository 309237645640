#colorbox {
  top: 100px;
  .page {
    padding-top: 0;
  }
  #edit-submitted-bestelbon-bestand-ajax-wrapper,
  .webform-component--administratie {
    display: none;
  }
  .chosen-container {
    width: 100% !important;
  }
}
//tmp
#edit-submitted-bestelbon-bestand-ajax-wrapper,
.webform-component--administratie {
  display: none;
}

/* Colorbox popup, based on the default style */

#cboxWrapper,
#cboxContent,
.cboxPhoto,
#cboxLoadingOverlay {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
#colorbox,
#cboxWrapper,
#cboxContent {
  background: #fff;
}
#cboxOverlay {
  background-color: #185C70;
}
#cboxLoadingOverlay {
  background: #fff;
}
#cboxPrevious,
#cboxPrevious:hover,
#cboxPrevious:focus {
  position: absolute;
  left: -1px;
  top: 48%;
  background: transparent url(../images/colorbox/left.svg) no-repeat center center / 30px 30px;
  width: 30px;
  height: 30px;
  margin: 0;
  border: 0 none;
  padding: 0;
}
#cboxPrevious:focus {
  outline: none;
}
#cboxNext,
#cboxNext:hover,
#cboxNext:focus {
  position: absolute;
  right: -1px;
  top: 48%;
  left: auto;
  width: 30px;
  height: 30px;
  background: transparent url(../images/colorbox/right.svg) no-repeat center center / 30px 30px;
}
#cboxNext:focus {
  outline: none;
}
#cboxCurrent {
  bottom: 0;
  left: 0;
  color: #fff;
  text-align: center;
  width: 100%;
}
#cboxClose,
#cboxClose:hover,
#cboxClose:focus {
  position: absolute;
  right: 5px;
  top: 5px;
  left: auto;
  width: 20px;
  height: 20px;
  background: transparent url(../images/colorbox/close.svg) no-repeat center center / 20px 20px;

}
#cboxClose:focus {
  outline: 1px solid #fff;
}