.node-training {

  .field-name-field-alert-2,
  .field-name-field-alert {
    //background-color: $orange;
    padding: 0.1rem 0.7rem;
    color: #fff;
    text-transform: uppercase;
    display: inline-block;
    font-size: 0.65rem;
    font-weight: bold;
  }

  .field-name-field-alert {
    background-color: $orange !important;
  }

  .field-name-field-alert-2 {
    background-color: $darkblue;
  }

  // List
  &.view-mode-search_result,
  &.view-mode-list {
    position: relative;
    .field-name-training-alerts {
      position: absolute;
      top: -1.45rem;
      right: 1rem;
      .field {
        margin-left: 10px;
      }
    }
    .field-name-field-basic-image-image,
    .field-name-field-training-image {
      margin: -0.58rem -0.58rem 0.58rem;
      @include media-breakpoint-up(xs) {
        margin: -0.58rem;
      }
    }
    .row {
      height: 100%;
      @include media-breakpoint-up(xs) {
        display: flex;
      }
    }
    .col-image {
      @include media-breakpoint-up(xs) {
        display: flex;
      }
      .background {
        width: calc(100% + 1.16rem);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: -0.58rem  -0.58rem 0.58rem;
        @include media-breakpoint-up(xs) {
          margin: -0.58rem;
        }
        .ratio {
          padding: 0 0 84%;
          height: 0;
          width: 100%;
        }
      }
    }
    .col-content {
      padding-top: 0.29rem;
      padding-left: 1rem;
    }
    .meta {
      font-size: 1rem !important;
      > div {
        display: block !important;
      }
    }
    .field-name-smart-training-date,
    .field-name-field-city {
      position: relative;
      padding-left: 30px;
      &::before {
        content: '\f133' !important;
        font-family: FontAwesome;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5rem;
        font-size: 0.94rem;
        font-style: normal;
        position: absolute;
        left: 0;
        top: 0;
        padding: 0 !important;
        color: $darkblue !important;
      }
    }
    .field-name-field-city {
      &::before {
        content: '\f041' !important;
      }
    }
    .field-name-field-city {
      position: relative;
      padding-left: 30px;
      &::before {
        content: '\f041' !important;
        font-family: FontAwesome;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5rem;
        font-size: 0.94rem;
        font-style: normal;
        position: absolute;
        left: 0;
        top: 0;
        padding: 0 !important;
      }
    }
  }

  // Title
  &.view-mode-title {
    position: relative;
    border-bottom: 1px solid $darkblue;
    padding: 0.8rem;
    background: #fff;
    margin: 0;
    &:last-child {
      border-bottom: 0 none;
    }
    p {
      margin: 0;
    }
    .field-name-title a {
      color: $darkblue;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.82rem;
    }
    .field-name-smart-training-date {
      position: relative;
      padding-left: 30px;
      font-size: 0.82rem;
      font-style: italic;
      &::before {
        content: '\f133';
        font-family: FontAwesome;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5rem;
        font-size: 0.94rem;
        font-style: normal;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .field-name-field-city {
      margin-left: 1.3rem;
      position: relative;
      padding-left: 20px;
      &::before {
        content: '\f041';
        font-family: FontAwesome;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5rem;
        font-size: 0.94rem;
        font-style: normal;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .field-name-field-city,
    .field-name-smart-training-date {
      font-size: 0.82rem;
      display: inline-block;
    }
  }

  // Square
  &.view-mode-square {
    position: relative;
    -webkit-border-radius:2px;
    -moz-border-radius:2px;
    border-radius:2px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
    margin-bottom: 20px;
    padding: 0 0 100% 0;
    height: 0 !important;
    background: $light;
    overflow: hidden;

    &::after {
      content: " ";
      width: 100%;
      height: 150px;
      background: -moz-linear-gradient(top,transparent 0%,rgba(0,0,0,0.6) 100%);
      background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,transparent),color-stop(100%,rgba(0,0,0,0.6)));
      background: -webkit-linear-gradient(top,transparent 0%,rgba(0,0,0,0.6) 100%);
      background: -o-linear-gradient(top,transparent 0%,rgba(0,0,0,0.6) 100%);
      background: -ms-linear-gradient(top,transparent 0%,rgba(0,0,0,0.6) 100%);
      background: linear-gradient(to bottom,transparent 0%,rgba(0,0,0,0.6) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000',endColorstr='#cc000000',GradientType=0);
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      visibility: visible;
      display: block;
    }
    img {
      width: 100%;
      display: block;
      transition: transform .5s;
      transform: scale(1.01,1.01);
      position: relative;

    }
    .meta {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 1rem 1rem;
      overflow: hidden;
      color: #fff;
      z-index: 3;
      font-size: 1.05rem;
      font-style: italic;
      margin-bottom: 0;
      transition: margin-bottom .5s;
      a {
        color: #fff;
      }
      h2 {
        font-size: 1.23rem;
        line-height: 1.53rem;
        text-transform: none;
        margin: 0 0 0.3em;
        font-style: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &::after {
          content: none;
        }
      }
    }
    .field-name-field-city {
      margin-bottom: 0.17rem;
      margin-top: 0.4rem;
    }
    &::before {
      background: $yellow;
      width: 100%;
      display: block;
      content: " ";
      height: 0.25rem;
      max-height: 0;
      transition: max-height 0.2s;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 4;
    }
    &:hover {
      &::before {
        max-height: 0.25rem;
      }
      img {
        transform: scale(1,1);
      }
      .meta {
        margin-bottom: 0.11rem;
      }
    }
  }

  &.view-mode-full {
    .group-header {
      background: $lightblue;
      margin: 0 -10px 10px;
      @include media-breakpoint-up(sm) {
        margin: 0 -20px 1.5rem;
      }
      @include media-breakpoint-up(lg) {
        margin: 0 -40px 1.5rem;
      }
      @include media-breakpoint-up(xl) {
        margin: 0 -70px 1.5rem;
      }
      /*@include breakpoint($breakpoint_xxl) {
        margin: 0 -90px 1.5rem;
      }*/
      h1 {
        position: absolute;
        color: #fff;
        font-size: 1.4rem;
        line-height: 1.9rem;
        bottom: 10px;
        left: 0;
        margin-bottom: 0;
        z-index: 2;
        @include media-breakpoint-up(sm) {
          font-size: 2.11rem;
          line-height: 2.5rem;
          bottom: 1rem;
        }

        &::after {
          display: none;
        }
      }
      .field {
        margin-bottom: 0;
      }
      .field-name-field-paragraphs {
        margin-bottom: 0;
        > div {
          margin-bottom: 1rem;
        }
        .field {
          margin-bottom: 0;
        }
      }
      .field-name-field-basic-image-image {
        position: relative;
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0,0,0,0.13);
          z-index: 1;
          content: '';
          display: block;
        }
        margin-left: -10px;
        margin-right: -10px;
        @include media-breakpoint-up(sm) {
          margin-left: -20px;
          margin-right: 0;
        }
        @include media-breakpoint-up(md) {
          margin-left: -30px;
        }
        @include media-breakpoint-up(lg) {
          margin-left: -40px;
        }
        @include media-breakpoint-up(xl) {
          margin-left: -70px;
        }
        /*@include breakpoint($breakpoint_xxl) {
          margin-left: -90px;
        }*/
      }
      .detail-header {
        position: relative;
        margin: 0 10px;
        @include media-breakpoint-up(sm) {
          min-height: 10rem;
          margin: 0 20px;
          width: calc(66.66666667% - 40px);
        }
        @include media-breakpoint-up(lg) {
          margin: 0 40px;
          width: calc(66.66666667% - 80px);
        }
        @include media-breakpoint-up(md) {
          margin: 0 30px;
          width: calc(66.66666667% - 60px);
        }
        @include media-breakpoint-up(xl) {
          margin: 0 70px;
          width: calc(66.66666667% - 140px);
        }
        /*@include breakpoint($breakpoint_xxl) {
          margin: 0 90px;
          width: calc(66.66666667% - 180px);
        }*/
      }
    }
    .detail-aside {
      @include media-breakpoint-up(sm) {
        position: absolute;
        right: 0;
        top: 20px;
      }
      @include media-breakpoint-up(lg) {
        top: 30px;
      }
      @include media-breakpoint-up(xl) {
        top: 40px;
      }

      .field-name-register-button {
        margin: 1rem 0 -45px;
        text-align: center;
      }
      .field-collection-item-field-pricing {
        > div {
          display: inline-block;
        }
        .field-name-field-label::before,
        .field-name-field_price_description::before {
          content: " - ";
          padding-left: 0.2rem;
        }
      }
      .training__code {
        margin-bottom: 1rem;
      }
      /*.custom-group {
        position: relative;
        padding-left: 30px;
        margin-bottom: 1rem;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: -4px;
          width: 20px;
          height: 20px;
          background: url(../images/alert--blue.svg) no-repeat;
        }
        .field {
          margin: 0;
        }
        h3 {
          line-height: 1.1rem;
          text-transform: uppercase;
          margin: 0;
          font-size: 0.85rem;
        }
      }*/
      .field-name-decoded-code {
        margin-bottom: 30px;
      }
      .custom-group,
      .field-name-field-dates,
      .field-name-smart-training-date,
      .field-name-smart-date-full,
      .field-name-field-address,
      .field-name-field-city,
      .where,
      .prices,
      .field-name-field-organization,
      .target,
      .field-name-field-type-training,
      .field-name-field-contact {
        position: relative;
        padding-left: 30px;
        margin-bottom: 1rem;
        &::before {
          content: '\f133';
          font-family: FontAwesome;
          display: inline-block;
          vertical-align: top;
          margin-right: 0.5rem;
          font-size: 0.94rem;
          font-style: normal;
          position: absolute;
          left: 0;
          top: -4px;
          color: $lightblue;
        }
        .field {
          margin: 0;
        }
        h3 {
          line-height: 1.1rem;
          text-transform: uppercase;
          margin: 0;
          font-size: 0.85rem;
        }
      }
      .custom-group {
        &::before {
          content: '\f29a';
        }
      }
      .field-name-field-dates,
      .field-name-smart-training-date,
      .field-name-smart-date-full {
        &::before {
          content: '\f133';
        }
        .date-display-range {
          display: inline-block;
        }
      }
      .field-name-field-address,
      .field-name-field-city,
      .where {
        &::before {
          content: '\f041';
        }
      }
      .target {
        &::before {
          content: '\f007';
        }
      }
      #days {
        .days-inner {
          display: none;
          font-size: 0.9rem;
        }
        &.open .days-inner {
          display: block;
          padding-bottom: 0.8rem;
          border-bottom: 1px solid #ccc;

        }
        .arrow-link {
          font-weight: normal;
          text-transform: none;
          font-size: 0.9rem;
          color: $darkblue;
          &::after {
            line-height: 0.9rem;
            font-size: 0.9rem;
            vertical-align: top;
            padding-top: 5px;
          }
        }
      }
      .prices {
        &::before {
          content: '\f0d6';
        }
      }
      .field-name-field-organization {
        &::before {
          content: '\f0c0';
        }
      }
      .field-name-field-contact {
        &::before {
          content: '\f003';
        }
      }
      .field-name-field-type-training {
        &::before {
          content: '\f29c';
        }
      }
    }
    .detail-content {
      > .field,
      .entity-paragraphs-item {
        margin-bottom: 1rem;
      }
    }
    .detail-header {
      position: relative;
      .field-name-training-alerts {
        position: absolute;
        right: 20px;
        bottom: -15px;
        z-index: 9;
        .field {
          margin-left: 10px;
        }
      }
    }

  }
  &.view-mode-full.past {
    .field-name-field-basic-image-image::after {
      content: "";
      background: rgba(255,255,255,0.7);
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      position: absolute;
    }
    .detail-aside .box::after {
      content: "";
      background: linear-gradient(180deg, rgba(255,255,255,0.695098107602416) 0%, rgba(255,255,255,1) 32%, rgba(255,255,255,1) 100%);
      top: 0;
      left: 15px;
      right: 15px;
      bottom: 0;
      position: absolute;
      z-index: 1;
    }
    .detail-aside .box::before {
      content: "Deze vorming is voorbij";
      color: $orange;
      font-weight: bold;
      top: 0;
      left: 15px;
      right: 15px;
      bottom: 0;
      position: absolute;
      z-index: 2;
      text-align: center;
      padding-top: 20%;
    }
  }
  &.view-mode-search_result.past {
    .background {
      position: relative;
    }
    .background::after {
      content: "";
      background: rgba(255,255,255,0.7);
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      position: absolute;
    }
  }
}

.col-sm-6 {
  .node-training.view-mode-search_result,
  .node-training.view-mode-list {
    .field-name-field-short-description,
    .field-name-field-target-audience {
      display: none;
    }
  }
}

#colorbox header, #colorbox footer, #colorbox .pane-page-breadcrumb {
  display: none;
}

.field-name-field-attachments {
  .file a {
    display: inline-block;
    margin: 0 1rem 1rem 0;
  }
}

// Full
body.node-type-training #row1 {
  padding-top: 0;
  margin-top: 0.588rem;
}

.show-filters,
.hide-filters {
  font-size: 16px;
  position: relative;
  padding-right: 17px;
  display: inline-block;
  *zoom: 1;
  *display: inline;
  text-decoration: none;
  margin: 0 0 1em;
}

.pane-pane-related-trainings {
  .box {
    padding: 0;
  }
}

.node-type-training {
  form.webform-client-form {
    @include media-breakpoint-up(sm) {
      width: 630px;
    }
    table * {
      box-sizing: border-box;
    }
    label.option {
      white-space: normal;
    }
    .webform-component--memberinfo {
      max-width: 100%;

      .form-item .form-text,
      textarea {
       // width: 80%;
      }
      tr.even, tr.odd {
        background-color: #fff;
        border: 1px solid $darkblue;
      }
      td {
        padding: 15px;
      }
      .form-select {
        width: 100% !important;
        max-width: 100% !important;
      }
      .form-item {
        margin-bottom: 15px;
      }
      .form-type-radio {
        display: block;
        margin-bottom: 0;
      }
      .button {
        margin: -20px auto 20px;
        display: block;
      }
    }
    label.option {
      font-weight: normal;
    }

    //matrix stuff
    .webform-component-matrix {
      .matrix-2,.matrix-4 {
        @include media-breakpoint-up(sm) {
          width: calc(50% - 15px);
          display: inline-block;
          margin-right: 15px;
        }
      }
      .matrix-3,.matrix-5 {
        @include media-breakpoint-up(sm) {
          width: 50%;
          display: inline-block;
        }
      }
      .removeline.line1 {
        display: none;
      }
      .removeline {
        .button {
          background: none;
          box-shadow: none;
          color: darkgrey;
          float: right;
        }
      }
    }

    font-size: 0;
    line-height: 0;
    padding: 10px;
    .form-item {
      font-size: 1rem;
      line-height: $base-line-height;
    }

    .form-item .form-text,
    textarea {
      //width: 615px;
      width: 100%;
    }
    .webform-component--voornaam,
    .webform-component--voornaam-contact,
    .webform-component--telefoon-contact,
    .webform-component--organisatie {
      @include media-breakpoint-up(sm) {
        width: calc(50% - 15px);
        margin-right: 15px;
        display: inline-block;
        vertical-align: top;
      }
    }
    .webform-component--naam,
    .webform-component--naam-contact,
    .webform-component--e-mail {
      @include media-breakpoint-up(sm) {
        vertical-align: top;
        width: 50%;
        display: inline-block;
      }
    }
    .webform-component--postcode {
      @include media-breakpoint-up(sm) {
        display: inline-block;
        vertical-align: top;
        width: calc(30% - 15px);
        margin-right: 15px;
      }
    }
    .webform-component--gemeente {
      @include media-breakpoint-up(sm) {
        vertical-align: top;
        width: 70%;
        max-width: 100%;
        display: inline-block;
      }
    }

    .price-volume {
      label {
        display: inline-block;
        width: 145px;
        vertical-align: middle;
      }
      .field-suffix {
        vertical-align: middle;
      }
      .form-text {
        width: 55px;
        padding: 5px 10px;
      }
    }

    .webform-submit {
      margin-top: 20px;
    }
  }
}

.page-vormingen-keuze-betaling {
  .field_wire_transfer_accountnr {
    font-weight: bold;
  }
  .bedrag {
    margin-top: 20px;
    font-weight: bold;
  }
  .mededeling {
    font-weight: bold;
  }
  .icons {
    margin-top: 10px;
    svg {
      margin-right: 5px;
    }

  }
  .box {
    margin-top: 40px;
  }

}


/* tables */
table.tablesorter {
  font-family:arial;
  background-color: #CDCDCD;
  margin:10px 0pt 15px;
  font-size: 8pt;
  width: 100%;
  text-align: left;
}
table.tablesorter thead tr th, table.tablesorter tfoot tr th {
  background-color: #e6EEEE;
  border: 1px solid #FFF;
  font-size: 8pt;
  padding: 4px;
  color: #333;
}
table.tablesorter thead tr .header {
  background-image: url(../images/bg.gif);
  background-repeat: no-repeat;
  background-position: center right;
  cursor: pointer;
  padding-right: 15px;
}
table.tablesorter tbody td {
  color: #3D3D3D;
  padding: 4px;
  background-color: #FFF;
  vertical-align: top;
}
table.tablesorter tbody tr.odd td {
  background-color:#F0F0F6;
}
table.tablesorter thead tr .headerSortUp {
  background-image: url(../images/asc.gif);
}
table.tablesorter thead tr .headerSortDown {
  background-image: url(../images/desc.gif);
}
table.tablesorter thead tr .headerSortDown, table.tablesorter thead tr .headerSortUp {
  background-color: $yellow;
}

.solidarity_prices_wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}