/* Map */

#google-map img {
  max-width: none;
}

.infoBox {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  width: 250px;
  line-height: 20px;
  font-size: 15px;
  font-family: $base-font-family;
  h3 {
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 5px 0;
  }
  p {
    margin: 0 0 5px 0;
  }
  &::before {
    border-color: #fff transparent transparent;
    border-style: solid;
    border-width: 15px;
    bottom: -30px;
    content: "";
    display: inline-block;
    *zoom: 1;
    *display: inline;
    margin: 0 0 0 115px;
    position: absolute;
  }
}



.infoBoxInner {
  padding: 10px;
  width: 230px;
}

