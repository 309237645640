/* colors */
$ecj-black: #010101;
$ecj-beige: #fff4e7;
$ecj-yellow: #FFD600;
$ecj-orange: #DD9201;
$ecj-green: #6ECA5D;
$ecj-dark-green: #01655C;
$ecj-blue: #89C5E9;
$ecj-dark-blue: #0156A8;
$ecj-pink: #EAC3E0;
$ecj-red: #FF3938;


/* transition */
$transition: all .25s ease;

/* font */
@font-face {
  font-family: 'BalboaPlus-Fill';
  src: url('../fonts/BalboaPlus-Fill.woff2') format('woff2'),
  url('../fonts/BalboaPlus-Fill.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


/* logo nl */
html:lang(nl) {
  body.ecj {
    header .logo-wrapper>.logo {
      background: url(../images/ecj/logo-nl.png) no-repeat;
      background-size: auto 50px;
      width: 111px;
      height: 50px;
      @include media-breakpoint-up(sm) {
        width: 144px;
        height: 65px;
        background-size: auto 65px;
      }
      @include media-breakpoint-up(md) {
        width: 180px;
        height: 81px;
        margin-top: 5px;
        background-size: auto 81px;
      }
    }
    &.scrolling {
      header .logo-wrapper> .logo {
        @include media-breakpoint-up(sm) {
          width: 135px;
          height: 55px;
          background-size: auto 55px;
        }
        @include media-breakpoint-up(md) {
          width: 160px;
          height: 70px;
          background-size: auto 70px;
        }
      }
    }
  }
}


/* logo en */
html:lang(en) {
  body.ecj {
    header .logo-wrapper>.logo {
      background: url(../images/ecj/logo-en.png) no-repeat;
      background-size: auto 50px;
      width: 111px;
      height: 50px;
      @include media-breakpoint-up(sm) {
        width: 144px;
        height: 65px;
        background-size: auto 65px;
      }
      @include media-breakpoint-up(md) {
        width: 180px;
        height: 81px;
        margin-top: 5px;
        background-size: auto 81px;
      }
    }
    &.scrolling {
      header .logo-wrapper> .logo {
        @include media-breakpoint-up(sm) {
          width: 135px;
          height: 55px;
          background-size: auto 55px;
        }
        @include media-breakpoint-up(md) {
          width: 160px;
          height: 70px;
          background-size: auto 70px;
        }
      }
    }
  }
}

body.ecj {
  color: $ecj-black;
  a {
    color: $ecj-dark-blue;
    text-decoration: underline;
  }
  .arrow-link {
    text-decoration: underline;
    &:after {
      display: none;
    }
  }
  .page button:not(.mfp-close),
  .page .button:not(.mfp-close),
  .page .form-submit,
  .mfp-content button:not(.mfp-close),
  .mfp-content .button:not(.mfp-close),
  .mfp-content .form-submit {
    padding: 12px 20px 16px 20px;
    font-size: 20px;
    font-family: 'BalboaPlus-Fill';
    font-weight: 400;
    line-height: 1.1;
    color: $ecj-black !important;
    text-decoration: none;
    background: $ecj-yellow;
    transition: $transition;
    &:hover,
    &:focus {
      color: $ecj-dark-blue;
      text-decoration: none;
      background: lighten($ecj-yellow,8%);
    }
    &:active,
    &:focus {
      outline: 0;
    }
  }
  .banner {
    background-color: $ecj-beige;
    h1 {
      font-family: 'BalboaPlus-Fill';
      font-weight: 400;
      line-height: 1.1;
      font-size: 36px;
      @include media-breakpoint-up(sm) {
        font-size: 50px;
      }
      @include media-breakpoint-up(md) {
        font-size: 60px;
      }
    }
  }
  ul + h2,
  p + h2 {
    margin-top: 30px;
  }
  h2 {
    font-size: 30px;
    font-family: 'BalboaPlus-Fill';
    font-weight: 400;
    line-height: 1.1;
    color: $ecj-dark-blue;
    @include media-breakpoint-up(md) {
      font-size: 40px;
    }
    &:after {
      display: none;
    }
  }
  .header {
    /* Desktop menu */
    .pane-menu-menu-ecj-en {
      float: right;
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      -ms-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
      display: none;
      zoom: 1;
      ul {
        margin: 0;
      }
      @include media-breakpoint-up(sm) {
        display: block;
        h2 {
          display: none;
        }
        ul.menu {
          font-size: 0;
          text-align: right;
          li {
            margin: 0;
            padding: 0;
            display: inline-block;
            font-size: $base-font-size;
            vertical-align: top;
          }
          a {
            color: $ecj-black;
            text-decoration: none;
            display: inline-block;
            transition: color 0.1s linear;
            -moz-transition: color 0.1s linear;
            -o-transition: color 0.1s linear;
            -webkit-transition: color 0.1s linear;
            padding: 10px;
            font-size: 20px;
            font-family: 'BalboaPlus-Fill';
            font-weight: 400;
            line-height: 32px;
            &::after {
              content: "";
              width: 25px;
              height: 13px;
              margin: 5px auto 0;
              opacity: 0;
              display: block;
              transition: $transition;
              background: url(../images/ecj/active-nav.svg) no-repeat;
            }
            &:hover,
            &:focus,
            &:active {
              color: $ecj-dark-blue;
              &::after {
                opacity: 1;
                transform: rotate(5deg);
              }

            }
            &.active ,
            &.active-trail {
              &::after {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    /* Mobile menu */
    .pane-mobile-menu-ecj {
      float: right;
      width: calc(100% - 195px);
      margin-right: 50px;
      @include media-breakpoint-up(sm) {
        display: none;
      }

      .pane-title {
        padding: 7px 10px 8px 45px;
        cursor: pointer;
        font-size: 20px;
        font-family: 'BalboaPlus-Fill';
        font-weight: 400;
        line-height: 25px;
        background: #fff url(../images/ecj/mobile-menu-icon.svg) no-repeat 10px 12px;
        background-size: 30px auto;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin: 0;
        border-radius: 2px;
        border: 1px solid $ecj-dark-blue;
        &::after {
          content: none;
        }
      }
      .pane-inner {
        margin: 14px auto 0;
        height: 43px;
        overflow: hidden;
      }
      ul.links,
      .language-switcher-locale-url {
        list-style-type: none;
        margin: 0 15px;
        li {
          margin: 0;
          padding: 0;
        }
        a {
          position: relative;
          padding: 5px 0;
          @include media-breakpoint-up(xs) {
            line-height: 40px;
          }
          text-decoration: none;
          display: block;
          text-transform: uppercase;
          color: $ecj-dark-blue;
          font-size: 14px;
          font-weight: bold;
          &.active-trail,
          &.active {
          }
          &:hover,
          &:focus {
            color: $ecj-red;
          }
        }
        .last a {
          border-bottom: 0 none;
        }
        ul li a {
          padding-left: 20px;
        }
      }
      .language-switcher-locale-url {
        a {
          font-size: 16px;
          padding: 9px 0;
          color: $ecj-red;
        }
      }
      .pane-inner.open {
        height: auto;
        overflow: visible;
        .pane-title {
          margin-bottom: -10px;
          padding-bottom: 20px;
          border-radius: 2px 2px 0 0;
          z-index: 101;
          position: relative;
          border-bottom: 0;
          padding-left: 10px;
          background-image: none;
          i {
            min-width: 18px;
          }
        }
        .mobile-menu-content {
          position: absolute;
          left: 0;
          right: 0;
          top: 66px;
          z-index: 100;
          background: #fff;
          border: 1px solid $ecj-dark-blue;
          border-radius: 2px;
          .links:nth-child(2),
          .language-switcher-locale-url {
            border-top: 1px solid #c5d7dc;
            a {
              text-transform: none;
              font-weight: normal;
              @include media-breakpoint-up(xs) {
                line-height: 30px;
              }
            }
          }
        }
      }
    }
  }
  /* language */
  .pane-locale-language {
    ul:before {
      display: none;
    }
    a {
      display: inline-block;
      margin: 5px 0;
      padding: 9px 20px;
      vertical-align: middle;
      border: 0;
      font-size: 20px;
      font-family: 'BalboaPlus-Fill';
      font-weight: 400;
      line-height: 1.1;
      color: $ecj-black;
      text-align: center;
      text-decoration: none;
      background: $ecj-yellow;
      border-radius: 30px;
      -webkit-appearance: none;
      cursor: pointer;
      min-height: 44px;
      transition: $transition;
      &:hover,
      &:focus {
        color: $ecj-dark-blue;
        text-decoration: none;
        background: lighten($ecj-yellow,8%);
      }
      &:active,
      &:focus {
        outline: 0;
      }
    }
  }
  .messages.error {
    background-color: $ecj-red;
  }
  ul.tabs li a {
    background-color: $ecj-dark-blue;
    &.active {
      background-color: $ecj-blue;
      color: $ecj-dark-blue;
    }
  }
  #row1 {
    &.panel-row.colored {
      position: relative;
      @include media-breakpoint-up(sm) {
        &:after {
          display: block;
          content: '';
          position: absolute;
          border: none;
          left: auto;
          top: auto;
          bottom: 20px;
          right: 40px;
          background: url(../images/ecj/illustration-02.png) no-repeat;
          background-size: 128px auto;
          width: 128px;
          height: 155px;
          margin-left: initial;
        }
      }
    }
  }
  .panel-row.darkblue {
    background-color: $ecj-dark-blue;
    .pane-title {
      color: white;
    }
  }
  .panel-row.yellow {
    background-color: $ecj-yellow;
    .pane-title {
      color: $ecj-dark-blue;
    }
    .pane-bundle-text {
      color: $ecj-black;
      a {
        color: $ecj-black;
      }
    }
  }
  .panel-row.grey {
    background: $ecj-beige;
    .pane-title {
      color: $ecj-dark-blue;
    }
    .pane-bundle-text {
      color: $ecj-black;
      a {
        color: $ecj-black;
      }
    }
  }
  #flockler-embed-17c266364c406e7ef385456511b01645 a.flockler-btn-load-more {
    font-size: 20px !important;
    font-family: 'BalboaPlus-Fill' !important;
    font-weight: 400 !important;
    background-color: $ecj-dark-blue !important;
    line-height: 25px !important;
    padding: 11px 35px 15px 35px !important;
  }
  footer {
    padding-top: 2.3rem;
    background-color: $ecj-dark-blue;
    //position: relative;
    color: $ecj-blue;
    @include media-breakpoint-up(sm) {
      &:after {
        content: '';
        position: absolute;
        top: -30px;
        right: 40px;
        background: url(../images/ecj/illustration-01.png) no-repeat;
        background-size: 200px auto;
        width: 200px;
        height: 150px;
      }
    }
    a {
      color: $ecj-blue;
      display: inline-block;
      position: relative;
      &:after {
        display: none;
      }
      &.logo {
        text-decoration: none;
        text-indent: -9999px;
        font-size: 0;
        height: 80px;
        background-repeat: no-repeat;
      }
      &.europeanchoralassociation {
        background-image: url(../images/ecj/logo-eca.png);
        width: 80px;
      }
      &.koorenstem {
        background-image: url(../images/ecj/logo-koor-en-stem.png);
        width: 181px;
      }
      &.flanders {
        background-image: url(../images/ecj/logo-flanders.png);
        width: 179px;
      }
      &.ghent {
        background-image: url(../images/ecj/logo-ghent.png);
        width: 113px;
      }
      &.vfjk {
        background-image: url(../images/ecj/logo-vfjk.png);
        width: 62px;
      }
      &.ecj-fb {
        text-decoration: none;
        background: url(../images/ecj/fb--blue.svg) no-repeat top 5px left 4px;
        padding-left: 22px;
      }
      &.ecj-ig {
        text-decoration: none;
        background: url(../images/ecj/ig--blue.svg) no-repeat top 6px left;
        padding-left: 22px;
        margin-bottom: 10px;
      }
    }
  }
  .footer-inner {
    @include media-breakpoint-up(sm) {
      display: flex;
      flex-wrap: wrap;
    }
    .pane-bundle-text {
      &:nth-child(1),
      &:nth-child(2) {
        @include media-breakpoint-up(sm) {
          flex: 1 0 25%;
          max-width: 25%;
        }
      }
      &:nth-child(3) {
        @include media-breakpoint-up(sm) {
          flex: 1 0 40%;
          max-width: 40%;
        }
        li {
          display: inline-block;
          margin: 0 30px 30px 0;
        }
      }
    }
  }
  .page {
    padding-top: 61px;
    @include media-breakpoint-up(sm) {
      padding-top: 61px;
    }
    @include media-breakpoint-up(md) {
      padding-top: 126px;
    }
  }
  .intro {
    font-style: normal;
    font-size: 22px;
    line-height: 30px;
    font-family: 'Brandon', sans-serif;
    color: $ecj-black;
    font-weight: 500;
  }
  table {
    th {
      color: $ecj-red;
    }
    td, th {
      padding: 8px 14px;
    }
    tr {
      border: 1px dashed $ecj-blue;
    }
  }
  .pane-bundle-free-block.lightblue {
    background-color: $ecj-pink !important;
    div[onclick] .hover {
      background-color: $ecj-dark-blue;
    }
    a {
      color: $ecj-dark-blue;
    }
  }
  .pane-bundle-free-block.yellow {
    background: $ecj-yellow !important;
    div[onclick] .hover {
      background-color: $ecj-dark-blue;
    }
    a {
      color: $ecj-dark-blue;
    }
  }
  .page {
    label,
    .webform-component-textfield.form-item-focus label {
      color: $ecj-dark-blue;
    }
    input.form-text,
    textarea {
      color: $ecj-dark-blue;
      border-color: $ecj-blue;
      &:focus {
        border-color: $ecj-dark-blue;
      }
    }
    .pane-bundle-faq,
    .field-name-field-qa {
      h3 {
        background: $ecj-beige url(../images/ecj/arrow-down.png) no-repeat right 15px center;
      }
      .field-name-field-answer {
        background: $ecj-beige;
      }
    }
  }
  .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
    color: $ecj-dark-blue;
  }
  .chosen-container .chosen-single,
  .chosen-container .chosen-choices,
  .chosen-container .chosen-drop {
    border-color: $ecj-blue;
    color: $ecj-dark-blue;
  }
  .chosen-container .chosen-results li {
    color: $ecj-dark-blue;
  }
  .chosen-container .chosen-results li.highlighted {
    color: $ecj-red;
  }
  ul.checks {
    li {
      padding-left: 25px;
      font-size: 20px;
      line-height: 28px;
      font-family: 'Brandon', sans-serif;
      font-style: normal;
      position: relative;
      margin-bottom: 3px;
      &:before {
        content: "";
        position: absolute;
        top: 6px;
        left: 0;
        width: 19px;
        height: 13px;
        transition: $transition;
        background: url(../images/ecj/list-icon.png) no-repeat;
      }
    }
  }
  .panel-row  {
    .col-sm-6 {
      .pane-bundle-image {
        img {
          transform: rotate(1deg);
        }
      }
    }
  }
  div.panels-ipe-pseudobutton-container a.panels-ipe-startedit {
    color: $white;
  }
  .field-name-ds-function-gallery-popup {
    a {
      &:nth-child(odd) {
        img {
          transform: rotate(1deg);
        }
      }
      &:nth-child(even) {
        img {
          transform: rotate(-1deg);
        }
      }
    }
  }
}