.node-item {

  .meta {
    margin: 0 0 0.5rem;
    font-size: 1rem;
    font-style: italic;
    > .field {
      margin: 0;
    }
  }
  .field-name-field-province,
  .field-name-field-city,
  .field-name-field-address {
    position: relative;
    padding-left: 30px;
    &::before {
      content: '\f041';
      font-family: FontAwesome;
      display: inline-block;
      vertical-align: top;
      margin-right: 0.5rem;
      font-size: 0.94rem;
      font-style: normal;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .field-name-post-date {
    position: relative;
    padding-left: 30px;
    font-style: italic;
    &::before {
      content: '\f133';
      font-family: FontAwesome;
      display: inline-block;
      vertical-align: top;
      margin-right: 0.5rem;
      font-size: 0.94rem;
      font-style: normal;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .field-name-smart-item-sub-title {
    margin: 0;
    color: $orange;
    text-transform: uppercase;
    font-size: 0.65rem;
    font-weight: bold;
    font-style: normal;
  }

  // Search result (square)
  &.view-mode-search_result,
  &.view-mode-square {
    background: $light;
    padding: 20px 15px;
    margin-bottom: 20px;
    position: relative;
    h2 {
      font-size: 1.1rem;
      border-bottom: 3px solid $lightblue;
      padding-bottom: 0.58rem;
    }
    .meta {
      margin: 0.9rem 0.3rem;
      font-size: 0.95rem;
    }
    &::before {
      background: $yellow;
      width: 100%;
      display: block;
      content: " ";
      height: 0.25rem;
      max-height: 0;
      -webkit-transition: max-height 0.2s;
      -moz-transition: max-height 0.2s;
      -ms-transition: max-height 0.2s;
      -o-transition: max-height 0.2s;
      transition: max-height 0.2s;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 4;
    }
    &:hover {
      &::before {
        max-height: 0.25rem;
      }
    }
  }

  // List
  &.view-mode-list {
    position: relative;
    .field-name-field-item-images {
      margin: -0.58rem;
    }
    .col-content {
      padding-top: 0.29rem;
      padding-left: 1rem;
    }
  }

  // Title
  &.view-mode-title {
    position: relative;
    border-bottom: 1px solid $darkblue;
    padding: 0.8rem;
    background: #fff;
    margin: 0;
    &:last-child {
      border-bottom: 0 none;
    }
    .meta {
      font-size: 0.82rem;
    }
    p {
      margin: 0;
    }
    .field-name-field-province,
    .field-name-field-city,
    .field-name-field-address,
    .field-name-post-date {
      font-size: 0.82rem;
      display: inline-block;
    }
    .field-name-field-province,
    .field-name-field-city,
    .field-name-field-address {
      margin-left: 1.3rem;
      padding-left: 20px;
    }
    .field-name-title a {
      color: $darkblue;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.82rem;
    }
  }

  &.view-mode-full {
    .meta {
      margin: -1rem 0 1rem;
    }
  }
}

.pane-pane-highlighted-item {
  .node {
    padding: 0;
    background: transparent;
    position: static;
  }
}

.page-zoekertjes {
  .main {
    position: relative;
  }
  .masonry {
    margin-top: 1rem;
    @include media-breakpoint-up(sm) {
      margin-top: 0;
    }
  }
  .view-mode-search_result {
    @include media-breakpoint-up(sm) {
      margin: 0 0 20px 0;
      width: calc(50% - 10px);
      float: left;
      &:nth-child(even) {
        float: right;
      }
    }
  }
}

.page-action {
  @include media-breakpoint-up(sm) {
    position: absolute;
    top: 1.3rem;
    right: 0;
  }
  width: auto !important;
  margin: 0 !important;
  p {
    margin: 0;
  }
}

.node-item-form {
  div.image-widget-data {
    float: none;
    .button {
      margin: 1rem 0 0 0;
      padding: 8px 15px;
    }

  }
  .form-type-managed-file {
    margin: 0;
  }
  .wysiwyg-toggle-wrapper,
  .format-toggle {
    display: none !important;
  }
}
.pane-pane-highlighted-item {
  position: relative;
}