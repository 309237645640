/*
 * Design
 *
 * Include all your general design elements in this file.
 */

/* Messages */

.pane-pane-messages {
  padding: 1em 0;
  position: relative;
  z-index: 2;
}

.messages {
  padding: 1em;
  color: $darkblue;

  a {
    color: #fff;
  }
  ul {
    margin-bottom: 0;
  }
}

.messages.error {
  background-color: $orange;
  color: #fff;
}
.messages.status {
  background-color: $lightblue;
}
.messages.warning {
  background-color: #fab619;
}


