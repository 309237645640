/* Sticky footer */
html {
  min-height: 100%;
  position: relative;
}

body {
  padding-bottom: 210px; // Desktop height of footer. Required for the sticky footer.
  &.cke_editable {
    padding-bottom: 0 !important;
  }
}

footer {
  background-color: $darkblue;
  color: #fff;
  padding-top: 1.76rem;
  padding-bottom: 2.64rem;
  font-size: 0.88rem;

  a {
    color: #fff;
    &:after {
      content: "\f105";
      font-weight: normal;
      padding-left: 5px;
      display: inline-block;
      *zoom: 1;
      *display: inline;
      vertical-align: text-top;
      font-size: 0.7rem;
      font-family: FontAwesome;
    }
    &.icon {
      display: inline-block;
      font-size: 1rem;
      width: 30px;
      height: 30px;
      overflow: hidden;
      i {
        width: 30px;
        text-align: center;
      }
      &::after {
        content: none;
      }
      &.europeanchoralassociation {
        text-indent: -1000px;
        overflow: hidden;
        background: transparent url(../images/logo-europa-cantat.png) no-repeat center center;
        width: 65px;
        height: 59px;
        background-size: 65px auto;
        display: block;
      }
      &.vlaanderen {
        text-indent: -1000px;
        overflow: hidden;
        background: transparent url(../images/logo-vlaanderen.png) no-repeat center center;
        width: 174px;
        height: 90px;
        background-size: 174px auto;
        display: block;
        margin-top: 1rem;
      }
    }
  }
  ul {
    list-style: none;
    margin: 0;
  }
  .panel-pane {
    margin-bottom: 1rem;
  }
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  .panels-ipe & {
    padding-bottom: 60px;
  }
}

.footer-inner {
  @include media-breakpoint-up(sm) {
    display: flex;
    flex-wrap: wrap;
  }
}