/*
 * Tabs
 */
ul.tabs {
  margin: 0 0 1em 0;
  li {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0 15px 0 0;
    a {
      padding: 3px 6px;
      background: $lightblue;
      border-radius: 2px;
      color: #fff;
      text-decoration: none;
      display: block;
      line-height: 20px;
    }
    a.active {
      font-weight: bold;
      background: transparent;
      color: $base-text-color;
    }
  }
}

/*
 * Top menu
 */
header .pane-menu-top-menu,
header .pane-menu-top-menu-en {
  display: none;
  @include media-breakpoint-up(sm) {
    display: block;
  }
  float: right;
  h2 {
    font-size: 1rem;
  }
  ul {
    margin: 0;
  }
  li {
    padding: 0;
    display: inline-block;
    font-size: 0.82rem;
    @include media-breakpoint-up(md) {
      margin: 0 5px;
    }
    a {
      color: $darkblue;
      text-decoration: none;
      display: inline-block;
      transition: color 0.1s linear;
      -moz-transition: color 0.1s linear;
      -o-transition: color 0.1s linear;
      -webkit-transition: color 0.1s linear;
      padding: 0.17rem;
      &:hover,
      &:focus {
        color: $base-link-hover-color;
      }
      &:active {
        color: $base-link-active-color;
      }
    }
  }
}


/*
 * Main menu:
 * Define two menu panes: one with a class 'main-menu' and
 * one with a class 'mobile-menu'.
 */
.header {
  /* Desktop menu */
  .main-menu {
    float: right;
    margin-top: 15px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    display: none;
    zoom: 1;
    ul {
      margin: 0;
    }
    ul.menu {
      a.external {
        @include media-breakpoint-up(sm) {
          -webkit-transition: all 0.2s;
          -moz-transition: all 0.2s;
          -ms-transition: all 0.2s;
          -o-transition: all 0.2s;
          transition: all 0.2s;
        }
        padding-right: 35px;
        position: relative;
        @include media-breakpoint-up(xl) {
          padding-right: 45px;
        }
        &::before {
          position: absolute;
          top: 0;
          right: 10px;
          width: 40px;
          height: 42px;
          content: '\f14c';
          display: block;
          font-family: FontAwesome;
          font-size: 0.8rem;
          line-height: 40px;
          text-align: center;
          font-weight: normal;
          @include media-breakpoint-up(sm) {
            width: 31px;
            height: 31px;
            line-height: 31px;
            padding-top: 4px;
          }
        }
      }
    }

    @include media-breakpoint-up(sm) {
      display: block;
      h2 {
        display: none;
      }
      ul.menu {
        font-size: 0;
        text-align: right;

        li {
          margin: 0;
          padding: 0;
          display: inline-block;
          font-size: $base-font-size;
          vertical-align: top;
        }
        a {
          color: $darkblue;
          text-decoration: none;
          display: inline-block;
          transition: color 0.1s linear;
          -moz-transition: color 0.1s linear;
          -o-transition: color 0.1s linear;
          -webkit-transition: color 0.1s linear;
          padding: 10px;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 0.88rem;
          line-height: 20px;
          &::after {
            content: "";
            height: 3px;
            width: 50px;
            margin: 5px auto 0;
            opacity: 0;
            transform: rotate(-5deg);
            background: $darkblue;
            display: block;
            -webkit-transition: all 0.2s;
            -moz-transition: all 0.2s;
            -ms-transition: all 0.2s;
            -o-transition: all 0.2s;
            transition: all 0.2s;
          }
          &:hover,
          &:focus,
          &:active {
            color: $darkblue;
            &::after {
              opacity: 1;
              background-color: $darkblue;
              transform: rotate(5deg);
            }

          }
          &.active ,
          &.active-trail {
            &::after {
              background: $lightblue;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  /* Mobile menu */
  .mobile-menu {
    float: right;
    width: calc(100% - 195px);
    margin-right: 50px;
    @include media-breakpoint-up(sm) {
      display: none;
    }

    .pane-title {
      padding: 9px 10px 9px 45px;
      cursor: pointer;
      text-transform: uppercase;
      background: #fff url(../images/mobile-menu-icon.png) no-repeat 10px 12px;
      background-size: 20px 18px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 0;
      line-height: 23px;
      font-size: 15px;
      border-radius: 2px;
      border: 1px solid $darkblue;
      &::after {
        content: none;
      }
    }
    .pane-inner {
      margin: 14px auto 0;
      height: 43px;
      overflow: hidden;
    }
    ul.links,
    .language-switcher-locale-url {
      list-style-type: none;
      margin: 0 15px;

      li {
        margin: 0;
        padding: 0;
      }
      a {
        position: relative;
        padding: 5px 0;
        @include media-breakpoint-up(xs) {
          line-height: 40px;
        }
        text-decoration: none;
        display: block;
        text-transform: uppercase;
        color: $darkblue;
        font-size: 14px;
        font-weight: bold;
        &.active-trail,
        &.active {
        }
        &:hover,
        &:focus {
          color: $lightblue;
        }
        &.external {
          display: inline-block;
          padding-right: 42px;
          position: relative;
          &::before {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
            width: 40px;
            height: 42px;
            content: '\f14c';
            display: block;
            font-family: FontAwesome;
            font-size: 0.8rem;
            line-height: 40px;
            text-align: center;
            font-weight: normal;
            @include media-breakpoint-up(sm) {
              width: 31px;
              height: 31px;
              line-height: 31px;
              padding-top: 4px;
            }
          }
        }
      }
      .last a {
        border-bottom: 0 none;
      }
      ul li a {
        padding-left: 20px;
      }
    }
    .pane-inner.open {
      height: auto;
      overflow: visible;
      .pane-title {
        margin-bottom: -10px;
        padding-bottom: 20px;
        border-radius: 2px 2px 0 0;
        z-index: 101;
        position: relative;
        border-bottom: 0;
        padding-left: 10px;
        background-image: none;
        i {
          min-width: 18px;
        }
      }
      .mobile-menu-content {
        position: absolute;
        left: 0;
        right: 0;
        top: 66px;
        z-index: 100;
        background: #fff;
        border: 1px solid $darkblue;
        border-radius: 2px;
        .links:nth-child(2),
        .language-switcher-locale-url {
          border-top: 1px solid #c5d7dc;
          a {
            text-transform: none;
            font-weight: normal;
            @include media-breakpoint-up(xs) {
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}

/* Pager */
.view > .item-list {
  clear: both;
  display: block;
  width: 100%;
}
.item-list .pager {
  font-size: 0;
  margin-top: 1rem;
  li {
    margin: 0 2px;
    padding: 0;
    display: inline-block;
    *zoom: 1;
    *display: inline;
    font-size: 0.94rem;
    line-height: 0.94rem;
    &.pager-last {
      margin-right: 0;
    }
    &.pager-first {
      margin-left: 0;
    }

    a {
      padding: 0.47rem 0.7rem;
      display: inline-block;
      *zoom: 1;
      *display: inline;
      background: $light;
      color: $darkblue;
      text-decoration: none;
      -moz-transition: all 0.1s ease;
      -webkit-transition: all 0.1s ease;
      transition: all 0.1s ease;

      &:hover,
      &:focus {
        background-color: $lightblue;
        color: #fff;
        text-decoration: none;
      }
    }
  }
  li.pager-current {
    background-color: transparent;
    padding: 0.588rem;
    font-weight: normal;
  }
  .pager-next a,
  .pager-last a,
  .pager-previous a,
  .pager-first a {
    background-color: transparent;
  }
}


.pane-locale-language {
  display: none;
  @include media-breakpoint-up(sm) {
   display: block;
    float: right;

    ul {
      margin: 0;
      list-style: none;
      &::before {
        content: '|';
      }
    }
    li {
      padding: 0;
      display: inline-block;
      font-size: 0.82rem;
      @include media-breakpoint-up(md) {
        margin: 0 5px;
      }
      a {
        color: $darkblue;
        text-decoration: none;
        display: inline-block;
        transition: color 0.1s linear;
        -moz-transition: color 0.1s linear;
        -o-transition: color 0.1s linear;
        -webkit-transition: color 0.1s linear;
        padding: 0.17rem;
        &:hover,
        &:focus {
          color: $base-link-hover-color;
        }
        &:active {
          color: $base-link-active-color;
        }
      }
    }
  }
}