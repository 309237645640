/* find a choir (map page) */

.vind-een-koor {
  padding-bottom: 0;
  header {
    margin:0;
  }
  .pane-page-content {
    padding: 0;
    margin-top: -7px;
  }
  .page label,
  .mfp-content label {
    color: $white;
  }
  .page, .mfp-content {
    .form-type-bef-checkbox {
      label {
        color: $darkblue;
        text-transform: none;
        font-size: 17px;
        cursor: pointer;
      }
    }
  }
  .mfp-content {
    form.campaignmonitor-subscribe-form {
      label {
        color: $darkblue;
      }
    }
  }
  .item-list .pager li.pager-current {
    background-color: $yellow;
  }
  .views-widget-filter-title label {
    display: none;
  }
  .page .form-type-textfield.form-item-focus input.form-text,
  .page .form-type-textfield.form-item-not-empty input.form-text {
    padding: 10px 14px;
  }
  .page input.form-text, .page textarea, .page select, .mfp-content input.form-text, .mfp-content textarea, .mfp-content select {
    min-height: 44px;
    height: 44px;
    border: none;
  }
  .page button:not(.mfp-close), .page .button:not(.mfp-close), .page .form-submit, .mfp-content button:not(.mfp-close), .mfp-content .button:not(.mfp-close), .mfp-content .form-submit {
    width: 100%;
  }
  .page input.form-text, .page textarea, .page select, .mfp-content input.form-text, .mfp-content textarea, .mfp-content select {
    width: 100%;
  }
  .page button:not(.mfp-close):hover, .page button:not(.mfp-close):focus, .page .button:not(.mfp-close):hover, .page .button:not(.mfp-close):focus, .page .form-submit:hover, .page .form-submit:focus, .mfp-content button:not(.mfp-close):hover, .mfp-content button:not(.mfp-close):focus, .mfp-content .button:not(.mfp-close):hover, .mfp-content .button:not(.mfp-close):focus, .mfp-content .form-submit:hover, .mfp-content .form-submit:focus {
    background-color: darken($lightblue,5%);
  }
  .page .form-item, .mfp-content .form-item {
    margin: 0 0 5px;
  }
  .item-list .pager {
    margin-top: 30px;
  }
  .views-exposed-form .views-exposed-widget {
    padding: .5em 0 0 0;
    .form-submit {
      margin-top: 7px;
    }
  }
}

body.not-front {
  &:not(.top-alert-visible) {
    &.not-logged-in {
      .map-wrapper .map {
        #map {
          @include media-breakpoint-up(sm) {
            height: calc(100vh - 148px) !important;
          }
          .view-koren {
            @include media-breakpoint-up(sm) {
              height: calc(100vh - 148px) !important;
            }
          }
          .leaflet-container {
            @include media-breakpoint-up(sm) {
              height: calc(100vh - 148px) !important;
            }
          }
          &.sticky {
            .map-container {
              .leaflet-container {
                @include media-breakpoint-up(sm) {
                  height: 100vh !important;
                }
              }
            }
          }
        }
      }
    }
    .map-wrapper .map {
      #map {
        @include media-breakpoint-up(sm) {
          height: calc(100vh - 227px) !important;
        }
        .view-koren {
          @include media-breakpoint-up(sm) {
            height: calc(100vh - 227px) !important;
          }
        }
        .leaflet-container {
          @include media-breakpoint-up(sm) {
            height: calc(100vh - 227px) !important;
          }
        }
        &.sticky {
          .map-container {
            .leaflet-container {
              @include media-breakpoint-up(sm) {
                height: 100vh !important;
              }
            }
          }
        }
      }
    }
  }
  &.top-alert-visible {
    &.not-logged-in {
      .map-wrapper .map {
        #map {
          @include media-breakpoint-up(sm) {
            height: calc(100vh - 188px) !important;
          }
          .view-koren {
            @include media-breakpoint-up(sm) {
              height: calc(100vh - 188px) !important;
            }
          }
          .leaflet-container {
            @include media-breakpoint-up(sm) {
              height: calc(100vh - 188px) !important;
            }
          }
          &.sticky {
            .map-container {
              .leaflet-container {
                @include media-breakpoint-up(sm) {
                  height: 100vh !important;
                }
              }
            }
          }
        }
      }
    }
    .map-wrapper .map {
      @include media-breakpoint-up(sm) {
        height: 100vh;
      }
      #map {
        @include media-breakpoint-up(sm) {
          height: calc(100vh - 267px) !important;
        }
        .view-koren {
          @include media-breakpoint-up(sm) {
            height: calc(100vh - 267px) !important;
          }
        }
        .leaflet-container {
          @include media-breakpoint-up(sm) {
            height: calc(100vh - 267px) !important;
          }
        }
        &.sticky {
          .map-container {
            .leaflet-container {
              @include media-breakpoint-up(sm) {
                height: 100vh !important;
              }
            }
          }
        }
      }
    }
  }
}


.map-wrapper {
  .leaflet-marker-icon {
    top: -40px;
  }
  .marker-cluster span {
    font-size: 14px;
    font-weight: bold;
  }
  .marker-cluster-medium {
    background-color: rgba(24,92,113,0.6) !important;
    div {
      background-color: $darkblue !important;
      color: $white !important;
    }
  }
  .marker-cluster-small {
    background-color: rgba(230,101,65,0.6) !important;
    div {
      background-color: $orange !important;
      color: $white !important;
    }
  }
  .marker-cluster-large {
    background-color: rgba(250,182,25,0.6) !important;
    div {
      background-color: $yellow !important;
    }
  }
  @include media-breakpoint-up(sm) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .map {
    @include media-breakpoint-up(sm) {
      flex: 1 0 60%;
      max-width: 60%;
      height: 100vh;
    }
    #map{
      width: 100%;
      height: 300px;
      @include media-breakpoint-up(sm) {
        height: 100vh;
      }
      .view-koren {
        height: 300px;
        width: 100%;
        @include media-breakpoint-up(sm) {
          height: 100vh;
        }
      }
      .leaflet-container {
        height: 300px !important;
        width: 100% !important;
        @include media-breakpoint-up(sm) {
          height: 100vh !important;
        }
      }
      &.sticky {
        .map-container {
          height: 100vh;
          position: fixed;
          top: 0;
          width: 60%;
        }
      }
    }
  }
  .list {
    position: relative;
    background-color: $darkblue;
    color: $white;
    padding: 15px 25px 30px 25px;
    @include media-breakpoint-up(sm) {
      flex: 1 0 40%;
      max-width: 40%;
      display: inline-flex;
      align-items: stretch;
      flex-flow: column;
    }
  }
}

.view-koren {
  .view-filters {
    + div {
      border-top: 0;
    }
  }
  #exposed-filters {
    margin-top: 0 !important;
  }
  .views-exposed-form {
    .views-exposed-widget {
      float: none;
      position: relative;
      @include media-breakpoint-up(sm) {
        flex: 1 0 50%;
        max-width: 50%;
        padding-left: 5px;
        padding-right: 5px;
        &.views-widget-filter-title {
          flex: 1 0 100%;
          max-width: 100%;
        }
      }
      &.views-submit-button {
        @include media-breakpoint-up(sm) {
          flex: 1 0 100%;
          max-width: 100%;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
    .views-exposed-widget:not(.views-widget-filter-title) {
      > label {
        position: relative;
        background-color: #E6F3F6;
        height: 44px;
        width: 100%;
        max-width: 100%;
        border: none;
        border-radius: 2px;
        vertical-align: middle;
        padding: 8px 14px;
        line-height: 30px;
        box-shadow: none;
        color: #185c71;
        font-size: 17px;
        cursor: pointer;
        text-transform: none;
        display: block;
        &:after {
          content: "";
          position: absolute;
          right: 12px;
          top: 20px;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #185c71;
          pointer-events: none;
        }
      }
      &.open {
        .views-widget {
          opacity: 1;
          display: block;
        }
      }
      .views-widget {
        display: none;
        transition: opacity 0.3s ease;
        position: absolute;
        top: 55px;
        width: 100%;
        left: 0;
        right: 0;
        background: #E6F3F6;
        padding: 15px 15px 10px 15px;
        border-radius: 3px;
        opacity: 0;
        overflow: visible;
        z-index: 5;
        @include media-breakpoint-up(sm) {
          width: calc(100% - 10px);
          left: 5px;
          right: 5px;
        }
        .toggle-more-options {
          color: $darkblue;
          text-decoration: underline;
          font-weight: bold;
          font-size: 17px;
        }
      }
    }
  }
  .views-exposed-widgets {
    margin-bottom: 15px;
    @include media-breakpoint-up(sm) {
      display: flex;
      flex-wrap: wrap;
      margin-left: -5px;
      margin-right: -5px;
    }
  }
  .views-widget-filter-title {
  }
  .node-choir.node-teaser {
    border-bottom: 1px solid #297C96;
    padding-bottom: 12px;
    margin-bottom: 8px;
    line-height: 14px;
    h3 {
      margin: 0;
      font-size: 14px;
      text-transform: uppercase;
      a {
        color: $white;
      }
    }

    .group-meta {
      font-size: 17px;
    }
    .group-choir-specs {
      margin-top: 5px;
      line-height: 19px;
      .field {
        position: relative;
        display: inline;
        &:not(:last-child):after {
          content: ',';
          padding-right: 0.3em;
        }
      }
    }
  }
  .views-widget-filter-field_city_value {
    .form-item {
      &.form-item-focus,
      &.form-item-focus:focus,
      &.form-item-focus:active,
      &.form-item-focus:focus-visible {
        outline: none;
      }
    }
  }
  .view-empty {
    margin-top: 15px;
  }
  .exposed-filter-value {
    border: none;
    background-color: $orange;
    font-size: 16px;
    padding: 0 6px 0 6px;
    color: $white;
  }
}

/* Leaflet popup */
.leaflet-popup {
  color: $base-text-color !important;
  font-size: 14px !important;
  h4 {
    margin: 0;
  }
  a {
    color: $base-text-color !important;
    font-size: 15px;
  }
}

.more-options {
  display: none;
  &.open {
    display: block;
  }
}

/* choir detail */

.node-type-choir {
  .field-name-choir-links {
    margin-bottom: 10px;
    a {
      height: 33px;
      overflow: hidden;
      position: relative;
      display: inline-block;
      text-indent: -9999px;
      font-size: 0;
      &.link--website {
        background: url(../images/social-website.svg) no-repeat left center;
        width: 33px;
      }
      &.link--instagram {
        background: url(../images/social-instagram.svg) no-repeat left center;
        width: 33px;
      }
      &.link--facebook {
        background: url(../images/social-facebook.svg) no-repeat left center;
        width: 25px;
      }
      &.link--twitter {
        background: url(../images/social-twitter.svg) no-repeat left center;
        width: 33px;
      }
      &.link--youtube {
        background: url(../images/social-youtube.svg) no-repeat left center;
        width: 33px;
      }
      &.link--mail {
        background: url(../images/social-mail.svg) no-repeat left center;
        width: 33px;
      }
      &:hover,
      &:focus {
        opacity: .5;
      }
    }
  }
  .field-name-field-choir-composition,
  .field-name-field-choir-type,
  .field-name-field-choir-rehearsal,
  .field-name-field-choir-description,
  .choir-related-wrapper,
  .group-locatie,
  .field-name-field-rehearsal-location {
    border-top: 1px solid #c5d7dc;
    padding: 7px 0;
    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
    }
    .label-inline,
    h3 {
      display: inline-block;
      color: $darkblue;
      font-weight: 500;
      @include media-breakpoint-up(md) {
        flex: 1 0 200px;
        max-width: 200px;
        margin-right: 0.5em;
      }
      a {
        color: $darkblue;
      }
    }
    .field-content {
      @include media-breakpoint-up(md) {
        flex: 1 0 calc(100% - 220px);
        max-width: calc(100% - 220px);
      }
    }
    h3 {
      font-size: inherit;
      font-weight: 500;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }
  .field-name-field-choir-description {
    .field-readmore {
      position: relative;
      &[aria-expanded="0"] {
        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          background-image: linear-gradient(180deg, rgba(255,255,255,0.00) 0%, #FFFFFF 100%);
          width: 100%;
          height: 87px;
          z-index: 0;
        }
      }
      a {
        position: relative;
        z-index: 1;
      }
    }
  }
  .row .col-md-6 {
    &:first-child {
      padding-bottom: 20px;
      @include media-breakpoint-up(md) {
        padding-right: 40px;
      }
    }
    &:last-child {
     // @extend .box;
    }
  }
  .group-locatie {
    position: relative;
  }
  .group-contact {
    position: relative;
    padding-left: 30px;
    margin-top: 20px;
    &:before {
      content: '\f0e0';
      font-family: FontAwesome;
      display: inline-block;
      vertical-align: top;
      margin-right: 0.5rem;
      font-size: 0.94rem;
      font-style: normal;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}


/* square */

.node-choir.view-mode-square {
  @extend .box;
  position: relative;
  margin-bottom: 1.17rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .field-name-choir-image {
    margin: -1.17rem -1.17rem 1.17rem;
    //overflow: hidden;
  }
  img {
    width: 100%;
    display: block;
    transform: scale(1.01,1.01);
    position: relative;
  }
  h2 {
    margin: 0 0 0.3rem;
  }
  .field-name-node-link {
    text-align: right;
    align-self: flex-end;
    margin-top: auto;
  }
  &::before {
    background: $yellow;
    width: 100%;
    display: block;
    content: " ";
    height: 0.25rem;
    max-height: 0;
    transition: max-height 0.2s;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 4;
  }
  &:hover {
    &::before {
      max-height: 0.25rem;
    }
    img {
      transform: scale(1,1);
    }
  }
}


/* title */

.node-choir.view-mode-title {
  border-bottom: 1px solid $grey;
  padding-bottom: 12px;
  margin-bottom: 8px;
  line-height: 14px;
  &:last-child {
    margin-bottom: 50px;
  }
  h3 {
    margin: 0;
    font-size: 14px;
    text-transform: uppercase;
  }
  .field-name-field-city {
    font-size: 17px;
  }
}


/* list */

.node-choir.view-mode-list {
  position: relative;
  .field-name-field-city {
    font-weight: 500;
  }
  .field-name-field-choir-type {
    font-style: italic;
    font-size: 15px;
    line-height: 23px;
    font-family: Lora,Georgia,"Times New Roman",Times,serif;
  }
  .field-name-choir-image {
    margin: -0.58rem;
  }
  .field-name-node-link {
    text-align: left;
  }
  .col-md-6:nth-child(2) {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0.7rem;
    @include media-breakpoint-up(sm) {
      padding-left: 25px;
    }
  }
}

.btn--meta {
  display: inline-block;
  margin-bottom: 15px;
}

.leaflet-container a{color:$lightblue !important;}
.leaflet-container a.leaflet-active {outline:2px solid orange;}
.leaflet-zoom-box{border:2px dotted $lightblue !important;}