/*
 * Tables
 */
table {
  width: 100%;
  border: 0 none;
  tbody {
    border: 0 none;
  }
  td,
  th {
    padding: 5px;
    border: 0 none;
    vertical-align: top;
  }
  th {
    font-weight: bold;
    font-size: 1.1rem;
    color: $lightblue;
  }
  tr {
    border-bottom: 1px solid $grey;
    &:last-child {
      border-bottom: 0;
    }
  }
}
.cke_editable {
  table tr {
    border-bottom: 1px solid $grey;
    &:last-child {
      border-bottom: 0;
    }
  }
}
table,
table * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
