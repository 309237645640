.front {
  header {
    margin-bottom: 0;
    border-bottom: 0;
  }
  .banner {
    margin-top: 0;
    .pane-bundle-text {
      position: absolute;
      bottom: 1.2rem;
      left: 0;
      width: 100%;
      color: #fff;
      text-shadow: 4px 4px 20px rgba(0,0,0,0.38);
      text-align: center;
      @include media-breakpoint-up(sm) {
        bottom: 20%;
      }
      .fieldable-panels-pane {
        margin: 0 auto;
        max-width: 80%;
      }
      p {
        font-size: 1rem;
        line-height: 1.3rem;
        font-weight: 500;
        margin: 0;
        @include media-breakpoint-up(xs) {
          font-size: 1.4rem;
          line-height: 1.7rem;
        }
        @include media-breakpoint-up(sm) {
          font-size: 1.78rem;
          line-height: 2.65rem;
          max-width: 40rem;
          margin: 0 auto;
        }
      }
      p:first-child {
        font-size: 1.7rem;
        line-height: 2rem;
        font-weight: bold;
        @include media-breakpoint-up(xs) {
          font-size: 2rem;
          line-height: 2.4rem;
        }
        @include media-breakpoint-up(sm) {
          font-size: 2.61rem;
          line-height: 3.2rem;
        }
      }
    }
  }
  .pane-title {
    font-size: 1.5rem;
    line-height: 1.9rem;
    @include media-breakpoint-up(xs) {
      font-size: 2.1rem;
      line-height: 2.3rem;
    }
  }
  .homepage-intro {
    text-align: center;
    h2 {
      font-size: 1.23rem;
      line-height: 2rem;
      display: inline-block;
      margin-bottom: 1rem;
      &::after {
        transform: none;
        margin: 0 auto;
      }
    }
  }
  // In case of top content block
  .pane-page-content {
    margin-top: -3.5rem;
  }
}

.language-en.front {
  .banner {
    .pane-bundle-text {
      p {
        @include media-breakpoint-up(sm) {
          max-width: 46rem;
        }
      }
    }
  }
}