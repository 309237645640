#sliding-popup {
	background: #86cdda;
	color: $darkblue;
	width: 100%;
	padding: 20px 0;
	position: fixed;
	z-index: 99999;
	font-size: 15px;
	label {
		color: $darkblue;
	}
}

.eu-cookie-compliance-content,
.eu-cookie-compliance-banner {
	@extend .clearfix;
	margin-left: auto;
	margin-right: auto;
	padding: 0 10px;
	@include media-breakpoint-up(sm) {
		padding: 0 20px;
	}
	@include media-breakpoint-up(md) {
		padding: 0 30px;
	}
	@include media-breakpoint-up(lg) {
		padding: 0 40px;
	}
	@include media-breakpoint-up(xl) {
		padding: 0 70px;
		max-width: 1800px;
	}
	/*@include breakpoint($breakpoint_xxl) {
		padding: 0 90px;
		max-width: 1900px;
	}*/
	#popup-text {
		color: $darkblue;
		a, p {
			color: $darkblue;
			margin: 0;
		}
		p {
			display: inline-block;
		}
	}
}

.eu-cookie-compliance-message {
	margin-bottom: 10px;
	@include media-breakpoint-up(md) {
		display: flex;
		align-items: center;
	}
	p {
		margin: 0;
		display: inline-block;
	}
}

.eu-cookie-compliance-more-button {
	background: transparent;
	border: none;
	border-radius: none;
	text-decoration: underline;
	padding: 0;
	color: $darkblue;
	font-size: rem-calc(15);
	@include media-breakpoint-up(md) {
		margin-left: 3px;
	}
}

.eu-cookie-compliance-save-preferences-button {
	background: transparent;
	border: none;
	border-radius: none;
	text-decoration: underline;
	padding: 0;
	color: $darkblue;
	font-size: rem-calc(15);
	margin-bottom: 10px;
}

.agree-button.eu-cookie-compliance-default-button {
	background: $darkblue;
	border: none;
	padding: 5px 12px;
	color: #ffffff;
	border-radius: 4px;
	width: 100%;
	margin: 5px 0;
	@include media-breakpoint-up(sm) {
		width: auto;
		margin: 0 10px 0 0;
	}
}

.eu-cookie-withdraw-button {
	background: #ffffff;
	color: $darkblue;
	border: none;
	padding: 5px 12px;
	border-radius: 4px;
	width: 100%;
	margin: 5px 0;
	@include media-breakpoint-up(sm) {
		width: auto;
		margin: 0;
	}
}

.eu-cookie-compliance-category {
	margin-bottom: 10px;
	label {
		position: relative;
		padding: 2px 0 2px 23px;
		display: inline-block;
		cursor: pointer;
		margin: 0;
		line-height: 21px;
		font-weight: 600;
		&:before {
			content: "";
			position: absolute;
			top: 3px;
			left: -3px;
			width: 20px;
			height: 20px;
			border: 1px solid $darkblue;
			background: transparent;
		}
		&:after {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			top: 1px;
			left: -4px;
			border-bottom: 2px solid transparent;
			border-left: 2px solid transparent;
			transform: rotate(-45deg);
			transform-origin: 18px -1px;
		}
	}
	input {
		opacity: 0;
		padding: 0;
		min-height: 0;
		position: absolute;
		overflow: hidden;
		clip: rect(0 0 0 0);
		height: 1px;
		width: 1px;
		margin: -1px;
		border: 0;
		&:checked + label:after {
			width: 10px;
			height: 6px;
			border-color: $darkblue;
			transition: height .08s ease-out, width .08s ease-out .1s;
		}
	}
}

.eu-cookie-compliance-category-description {
	font-size: 14px;
	line-height: 20px;
	padding-left: 23px;
}