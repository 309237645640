.advice-header {
  background-color: $lightblue;
  padding: 40px 20px 20px 20px;
  text-align: center;
  .pane-pane-search-filter,
  .advice-header__tags {
    h2 {
      display: none;
    }
  }
  .pane-pane-search-filter {
    margin-left: auto;
    margin-right: auto;
  }
  .advice-header__tags {
    text-align: center;
    margin-top: 25px;
    .facetapi-facetapi-links {
      list-style-type: none;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
      li {
        display: inline-block;
        margin: 8px 7px;
        a {
          background-color: $darkblue;
          color: $white;
          border: none;
          padding: 5px 14px;
          font-size: 19px;
          &.facetapi-active {
            color: $white;
            padding: 5px 36px 5px 16px;
            background: $darkblue url(../images/close-white.svg) no-repeat top 11px right 12px;
          }
        }
      }
    }
    .facet-count {
      display: none;
    }
  }
}

.advice-content {
  margin-top: 50px;
  .pane-pm-existing-pages {
    @include media-breakpoint-up(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 20px;
    }
    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 0 30px;
    }
    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}
.advice-extra {
  clear: both;
}