.node-panopoly-landing-page {

  // General fields

  // Square
  &.view-mode-square {
    @extend .box;
    position: relative;
    margin-bottom: 1.17rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .field-name-field-basic-image-image {
      margin: -1.17rem -1.17rem 1.17rem;
      //overflow: hidden;
    }
    img {
      width: 100%;
      display: block;
      transition: transform .5s;
      transform: scale(1.01,1.01);
      position: relative;
    }
    h2 {
      margin: 0 0 0.3rem;
    }
    .meta {
      font-style: italic;
      font-size: 15px;
      > div {
        display: inline-block;
        &::before {
          content: "-";
          padding: 0 5px;
        }
        &:first-child::before {
          content: none;
        }
      }
    }
    .field-name-node-link {
      text-align: right;
      align-self: flex-end;
      margin-top: auto;
    }
    &::before {
      background: $yellow;
      width: 100%;
      display: block;
      content: " ";
      height: 0.25rem;
      max-height: 0;
      -webkit-transition: max-height 0.2s;
      -moz-transition: max-height 0.2s;
      -ms-transition: max-height 0.2s;
      -o-transition: max-height 0.2s;
      transition: max-height 0.2s;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 4;
    }
    &:hover {
      &::before {
        max-height: 0.25rem;
      }
      img {
        transform: scale(1,1);
      }
    }
  }

  // List
  &.view-mode-search_result,
  &.view-mode-list {
    .field-name-field-city {
      position: relative;
      padding-left: 30px;
      &::before {
        content: '\f041';
        font-family: FontAwesome;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5rem;
        font-size: 0.94rem;
        font-style: normal;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .field-name-smart-date {
      position: relative;
      padding-left: 30px;
      &::before {
        content: '\f133';
        font-family: FontAwesome;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5rem;
        font-size: 0.94rem;
        font-style: normal;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  // Full
  &.view-mode-full {
  }
}

.banner {
  background-color: $lightblue;
  background-size: cover;
  background-position: center center;
  height: 0;
  padding: 0 0 63%;
  position: relative;
  margin-top: 0.588rem;
  @include media-breakpoint-up(xs) {
    padding: 0 0 40%;
  }
  @include media-breakpoint-up(sm) {
    padding: 0 0 36%;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.8) 100%);
    z-index: 1;
  }
  &.banner-minimal {
    height: auto;
    padding: 40px 0 20px;
    h1,
    .title-wrapper,
    .extra-title {
      position: relative;
      bottom: 0;
      text-shadow: none;
    }
    &::after {
      content: none;
    }
  }
  .extra-title {
    margin-bottom: 10px;
    color: #fff;
  }
  .title-wrapper {
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
  }
  h1 {
    color: #fff;
    &::after {
      content: none;
    }
  }
  div.logo {
    position: absolute;
    bottom: 0;
    margin: 0 0 2rem 0;
    max-width: 45%;
  }

  .caption {
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
    z-index: 2;
    color: #fff;
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-family: Lora, Georgia, "Times New Roman", Times, serif;
    font-size: 0.9rem;
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
      bottom: 13px;
      right: 15px;
    }
    &.captions {
      display: none;
    }
  }
}

// FAQ block
// FAQ
.page .pane-bundle-faq,
.page .field-name-field-qa {
  h3 {
    cursor: pointer;
    background: $light url(../images/arrow-down.png) no-repeat right 15px center;
    background-size: 9px auto;
    display: block;
    clear: both;
    padding: 10px 30px 10px 15px;
    margin: 10px 0 0 0;
    font-weight: 500;
  }
  .field-name-field-answer {
    display: none;
    background: $light;
    padding: 0 15px 15px;
    p:last-child {
      margin-bottom: 0;
    }
  }
  .open {
    .field-name-field-answer {
      display: block;
    }
  }
}

.pane-bundle-popup {
  display: none;
}

.logged-in .pane-bundle-popup {
  display: block; 
}