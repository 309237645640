/*
 * Forms
 */

.page,
.mfp-content {
  input.form-text,
  textarea,
  select {
    width: 450px;
    max-width: 100%;
    border: 1px solid $darkblue;
    border-radius: 2px;
    vertical-align: middle;
    padding: 10px 14px;
    line-height: 24px;
    box-shadow: none;
    color: #185c71;
    font-size: 17px;
    min-height: 55px;
    @include media-breakpoint-up(md) {
      min-height: 60px;
    }
  }
  select {
    cursor: pointer;
  }
  label {
    font-size: 14px;
    color: $darkblue;
    font-weight: bold;
    text-transform: uppercase;
  }
  .webform-two-columns {
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
    .fieldset-wrapper {
      @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        .form-item.webform-component {
          flex: 1 0 50%;
          max-width: 50%;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
  .webform-three-columns {
    border: none;
    padding: 0;
    margin: 0;
    width: 100%;
    .fieldset-wrapper {
      @include media-breakpoint-up(sm) {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        .form-item.webform-component {
          flex: 1 0 33.33%;
          max-width: 33.33%;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
  .form-type-radio {
    label {
      font-weight: 400;
      text-transform: none;
    }
  }
  .label-inline {
    display: inline-block;
    margin-right: 0.5em;
  }
  /*input.webform-calendar {
    background-image: url(../images/calendar.svg) !important;
  }*/
  select,
  .chosen-container {
    &.day,
    &.year,
    &.hour{
      width: 80px !important;
    }
    &.month,
    &.minute  {
      width: 100px !important;
    }
    &.year {
      width: 100px !important;
    }
    .chosen-search {
      display: none !important;
    }
  }
  button:not(.mfp-close),
  .button:not(.mfp-close),
  .form-submit {
    display: inline-block;
    *zoom: 1;
    *display: inline;
    color: #fff;
    background: $lightblue;
    text-transform: uppercase;
    font-size: 0.75rem;
    padding: 15px 35px;
    font-weight: bold;
    -webkit-box-shadow:  0px 8px 20px rgba(0,0,0,0.13);
    -moz-box-shadow: 0px 8px 20px rgba(0,0,0,0.13);
    box-shadow: 0px 8px 20px rgba(0,0,0,0.13);
    border: 0 none;

    text-decoration: none;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
    
    &:hover,
    &:focus {
      background-color: $darkblue;
      -webkit-box-shadow:  0px 0px 15px rgba(0,0,0,0.13);
      -moz-box-shadow: 0px 0px 15px rgba(0,0,0,0.13);
      box-shadow: 0px 0px 15px rgba(0,0,0,0.13);
    }
    &:active {
      background-color: #134757;
    }
  }
  .form-required {
  }
  .form-item {
    margin: 0 0 1em;
    position: relative;
    &.form-type-radio {
      margin: 0;
    }
  }
  .form-type-textfield,
  .form-type-password,
  .webform-component-textfield,
  .webform-component-email,
  .form-type-date-popup {
    > label {
      padding: 0;
      position: absolute;
      left: 11px;
      top: 15px;
      text-transform: uppercase;
      font-size: 14px;
      color: $darkblue;
      font-weight: bold;
      @include media-breakpoint-up(sm) {
        left: 14px;
        top: 15px;
      }
      margin: 0;
      -webkit-transition: all 0.2s ease;
      -moz-transition: all 0.2s ease;
      -ms-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      transition: all 0.2s ease;
      z-index: 5;
      .form-required {
        color: $darkblue;
      }
    }
    &.form-item-not-empty,
    &.form-item-focus {
      zoom: 1;
      label {
        top: 5px;
        font-size: 11px;
        color: $darkblue;
        font-weight: bold;
        .form-required {
          color: $darkblue;
        }
      }
      input.form-text {
        padding-top: 24px;
        padding-bottom: 4px;
      }
      .input-wrapper {
        position: relative;
        width: 550px;
        max-width: 100%;
      }
    }
  }
  .webform-container-inline {
    .form-item {
      margin-bottom: 2px;
    }
  }
  .container-inline div,
  .container-inline label {
    display: inline-block;
  }
  .form-select.day,
  .form-select.month,
  .form-select.year {
    width: auto;
    &::after {
      display: none;
    }
  }
  .webform-component-date {
    .form-type-select {
      margin-right: 9px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .webform-datepicker .form-type-select {
    display: inline-block;
    &::after {
      display: none;
    }
  }
  .webform-datepicker .webform-calendar {
    display: none !important;
  }
  .webform-component-matrix:not(.webform-component--memberinfo) {
    table {
      td {
        padding: 14px 20px 1px 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }
      + input.form-submit {
        margin-top: 20px;
      }
    }
    .form-type-item {
      margin: 0 25px 20px 0;
    }
    .form-type-textfield {

    }
    .matrix-1 {
      margin: 0 25px 20px 0;
      input.form-text {
        min-height: 40px !important;
      }
      .webform-container-inline {
        display: flex;
        flex-wrap: wrap;
        .form-item:nth-child(1),
        .form-item:nth-child(3) {
          margin-right: 5px;
        }
        .form-item:nth-child(2) {
          order: 3;
        }
      }
    }
    .matrix-2,
    .matrix-3,
    .matrix-4 {
      margin: 0 25px 20px 0;
      &.Tekst {
        margin: 0 25px 20px 0;
      }
      .chosen-container {
        width: 130px !important;
      }
      input.form-text {
        min-height: 40px !important;
      }
    }
    .removeline {
      margin-bottom: 20px;
      @include media-breakpoint-up(sm) {
        margin-bottom: 0;
      }
      .button {
        padding: 0;
        background: transparent url(../images/delete--blue.svg) no-repeat top left;
        padding-left: 30px;
        color: $darkblue;
        text-decoration: underline;
        box-shadow: none;
        min-height: 22px;
      }
    }
  }
}

fieldset {
  border: 1px solid $darkblue;
  width: 450px;
  max-width: 100%;
  padding: 0.8rem;
  margin: 0 0 1rem 0;
}

/* Chosen fields */
.chosen-container {
  width: 100% !important;
  @include media-breakpoint-up(sm) {
    width: 450px !important;
  }
  .chosen-single,
  .chosen-choices {
    border: 1px solid $darkblue;
    border-radius: 0;
    background: #fff;
    padding: 5px 15px;
    height: 48px;
    line-height: 40px;
    box-shadow: none;
    color: $base-text-color;
    font-size: $base-font-size;
    div b {
      background-position: 0 15px;
    }
  }
  .chosen-drop {
    border-color: $darkblue;
    box-shadow: none;
    border-radius: 0;
    font-size: 15px;
    line-height: 28px;
    background: #fff;
  }
  .chosen-results li {
    padding: 8px 6px;
    &.highlighted {
      background: none;
      color: $darkblue;
    }
  }
}
.chosen-container-active.chosen-with-drop .chosen-single {
  background: #fff;
  border: 1px solid $darkblue;
}
.chosen-container-active.chosen-with-drop .chosen-single div b {
  background-position: -16px 15px;
}
.chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  color: $base-text-color;
  font-size: $base-font-size;
  font-family: $base-font-family;
}
.homepage-textfield {
  display: none;
}
fieldset.lineup {
  border: 0 none;
  padding: 0;
  legend {
    display: none;
  }
  .fieldset-wrapper {
    @include media-breakpoint-up(sm) {
      display: flex;
      flex-wrap: nowrap;
      margin-left: -10px;
      margin-right: -10px;
      justify-content: space-between;
      > div {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
}