/* search result */

.node-blog.view-mode-search_result {
  padding: 0;
  .group-header {
    position: relative;
    overflow: hidden;
  }
  .title-wrapper {
    position: absolute;
    left: 25px;
    right: 25px;
    bottom: 20px;
  }
  .field-name-field-blog-image-teaser {
   position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 150px;
      background-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.8) 100%);
      z-index: 1;
    }
  }
  .field-name-field-extra-title {
    position: relative;
    z-index: 2;
    color: $white;
    margin-bottom: 10px;
    text-shadow: 0 0 10px rgba(0,0,0,0.5);
  }
  .field-name-title {
    position: relative;
    z-index: 2;
    h2 {
      margin: 0;
      text-shadow: 0 0 10px rgba(0,0,0,0.5);
      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
        line-height: 31px;
      }
      a {
        color: $white;
      }
    }
  }
  .group-footer {
    padding: 25px;
  }
  .meta {
    .field {
      display: inline-block;
      margin-right: 3px;
      position: relative;
      &.field-name-post-date {
        &:after {
          content: ' -';
        }
      }
      + .field {
        &:not(:last-child) {
          &:after {
            content: ',';
          }
        }
      }
    }
  }
  p {
    margin: 0;
  }
  br {
    display: none;
  }
  .field-name-node-link {
    a {
      text-decoration: none;
      font-size: 0.76rem;
      text-transform: uppercase;
      font-weight: bold;
      display: inline-block;
      &:after {
        content: "\f105";
        font-weight: normal;
        line-height: 1rem;
        padding-left: 5px;
        display: inline-block;
        *zoom: 1;
        *display: inline;
        vertical-align: text-top;
        font-family: FontAwesome;
      }
      &.arrow-link-down::after {
        content: '\f107';
      }
      &.arrow-link-up::after {
        content: '\f106';
      }
    }
  }
}


/* detail page */

.node-blog.view-mode-full {
  .meta {
    position: relative;
    font-style: italic;
    margin-bottom: 25px;
    .field {
      display: inline-block;
      margin-right: 3px;
      //position: relative;
      &.field-name-post-date {
        &:after {
          content: ' -';
        }
      }
      + .field {
        &:not(:last-child) {
          &:after {
            content: ',';
          }
        }
      }
    }
  }
  .paragraphs-items {
    @include media-breakpoint-up(md) {
      max-width: 900px;
    }
  }
  .media-youtube-video iframe {
    width: 100%;
    vertical-align: top;
    //margin-bottom: 1rem;
  }
  .field-name-field-caption {
    margin: 8px 0 30px 0;
    font-size: 16px;
    line-height: 20px;
  }
}
/* Related blog row */
.related-blog {
  margin-bottom: 2rem;
  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
    .node {
      flex: 1 0 calc(50% - 15px);
      max-width: calc(50% - 15px);
      &:nth-child(1) {
        margin-right: 15px;
      }
      &:nth-child(2) {
        margin-left: 15px;
      }
    }
  }
}


/* blog sidebar categorie */
.col-sm-4 {
  .panel-pane.pane-facetapi-qoa8a5mg92uqheyqkagbblugrvzhrpo9 {
    .pane-title {
      @include media-breakpoint-up(sm) {
        margin-top: 100px;
      }
    }
  }
}


.pane-pane-search-filter {
  position: relative;
  max-width: 450px;
  form {
    position: relative;
    .form-item {
      margin-bottom: 0;
    }
    .button {
      padding: 8px 16px !important;
      position: absolute !important;
      right: 3px !important;
      top: 5px !important;
      background: #fff url(../images/arrow-right.png) no-repeat center center !important;
      background-size: 24px 24px !important;
      width: 40px !important;
      height: 44px !important;
      text-indent: 30px !important;
      overflow: hidden !important;
      box-shadow: none !important;
      &:hover,
      &:focus {
        right: 2px !important;
      }
    }
    .form-text {
      padding-right: 50px;
    }
  }
}

.item-list ul.facetapi-facet-im-field-blog-series {
  margin: 0;
  li {
    border-bottom: 1px solid #c5d7dc;
    margin: 0;
    padding: 0;
    list-style: none;
    &:first-child {
      border-top: 1px solid #c5d7dc;
    }
    a {
      display: block;
      padding: 5px 0;
      color: $darkblue;
      &:hover,
      &:focus {
        color: $lightblue;
      }
    }
  }
}

.view-popular-blogs {
  .views-row {
    border-bottom: 1px solid #c5d7dc;
    &:first-child {
      border-top: 1px solid #c5d7dc;
    }
    a {
      display: block;
      padding: 5px 0;
      color: $darkblue;
      &:hover,
      &:focus {
        color: $lightblue;
      }
    }
  }
}


/* square */

.node-blog.view-mode-square {
  @extend .box;
  position: relative;
  margin-bottom: 1.17rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  overflow: hidden;
  .field-name-field-blog-image-teaser {
    margin: -1.17rem -1.17rem 1.17rem;
    //overflow: hidden;
  }
  img {
    width: 100%;
    display: block;
    transform: scale(1.01,1.01);
    position: relative;
  }
  h2 {
    margin: 0 0 0.3rem;
  }
  .field-name-node-link {
    text-align: right;
    align-self: flex-end;
    margin-top: auto;
  }
  &::before {
    background: $yellow;
    width: 100%;
    display: block;
    content: " ";
    height: 0.25rem;
    max-height: 0;
    transition: max-height 0.2s;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 4;
  }
  .meta {
    .field {
      display: inline-block;
      margin-right: 3px;
      position: relative;
      &.field-name-post-date {
        &:after {
          content: ' -';
        }
      }
      + .field {
        &:not(:last-child) {
          &:after {
            content: ',';
          }
        }
      }
    }
  }
  &:hover {
    &::before {
      max-height: 0.25rem;
    }
    img {
      transform: scale(1,1);
    }
  }
}


/* title */

.node-blog.view-mode-title {
  border-bottom: 1px solid $grey;
  padding-bottom: 12px;
  margin-bottom: 8px;
  line-height: 14px;
  &:last-child {
    margin-bottom: 50px;
  }
  h3 {
    margin: 0;
    font-size: 14px;
    text-transform: uppercase;
  }
}


/* list */

.node-blog.view-mode-list {
  position: relative;
  .field-name-field-city {
    font-weight: 500;
  }
  .field-name-field-blog-image-teaser {
    margin: -0.58rem;
  }
  .field-name-node-link {
    text-align: left;
  }
  .col-md-6:nth-child(2) {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 0.7rem;
    @include media-breakpoint-up(sm) {
      padding-left: 25px;
    }
  }
}


/* author */

.author-wrapper {
  margin-top: 40px;
  max-width: 900px;
  background: #ffffff;
  padding: 15px 20px;
  box-shadow: 0 0 11px 0 rgba(0,0,0,0.3);
  @include media-breakpoint-up(sm) {
    padding: 20px 25px;
  }
  .node {
    @include media-breakpoint-up(sm) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .field-name-field-basic-image-image {
    margin-bottom: 20px;
    @include media-breakpoint-up(sm) {
      flex: 1 0 100px;
      max-width: 100px;
      margin-bottom: 0;
    }
  }
  .field-name-field-short-description {
    @include media-breakpoint-up(sm) {
      flex: 1 0 calc(100% - 100px);
      max-width: calc(100% - 100px);
      padding-left: 20px;
    }
  }
  img {
    border-radius: 50%;
    width: 100%;
    max-width: 100px;
    height: auto;
  }
}