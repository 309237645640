/* General facets */
.facet-count {
  font-style: italic;
  color: $lightblue;
  font-size: 0.9rem;
  margin-left: 0.2rem;
}
.item-list .facetapi-facetapi-checkbox-links {
  border-top: 1px solid #c5d7dc;
  margin: 0;
  a {
    color: $darkblue;
    display: inline-block;
    *zoom: 1;
    *display: inline;
    text-decoration: none;
  }
  li {
    padding: 5px 5px 5px 30px;
    list-style: none;
    position: relative;
    margin: 0;
    border-bottom: 1px solid #c5d7dc;
  }
  input.facetapi-checkbox {
    margin: 0;
    position: absolute;
    left: 5px;
    top: 11px;
  }
}

/* Date filter */
.pane-pane-date-filter {
  ul {
    list-style: none;
    margin: 0;
    border-top: 1px solid $grey;
  }
  a {
    color: $darkblue;
    display: block;
    text-decoration: none;
    &:hover,
    &:focus {
      color: $lightblue;
    }
  }
  li {
    padding: 5px;
    list-style: none;
    position: relative;
    margin: 0;
    border-bottom: 1px solid $grey;
  }
  #project-date-form {
    padding: 10px;
    background: $lightblue;
    margin-top: -1px;
    border-radius: 0 0 2px 2px;
    .form-submit {
      padding: 8px 16px;
      background-color: $darkblue;
      color: #fff;
    }
  }
  .activedate {
    font-weight: bold;
  }
  .date-padding {
    display: block;
    *zoom: 1;
    *display: inline;
    label {
      display: none;
    }
    .description {
      margin-left: 0;
      display: none;
    }
  }
  input.form-text {
    border: 0 none;
  }
  .container-inline-date .form-item .form-item,
  .container-inline-date .date-padding {
    float: none;
  }
  .container-inline-date > .form-item {
    display: block;
  }
}

/* Location filter */
#project-location-form {
  position: relative;
  max-width: 450px;
  .form-item {
    margin-bottom: 0;
  }
  .form-submit {
    padding: 8px 16px;
    position: absolute;
    right: 3px;
    top: 5px;
    background: #fff url(../images/arrow-right.png) no-repeat center center;
    background-size: 24px 24px;
    width: 40px;
    height: 44px;
    text-indent: 30px;
    overflow: hidden;
    box-shadow: none;
    &:hover,
    &:focus {
      right: 2px;
    }
  }
  .form-text {
    padding-right: 50px;
  }
}

/* */
.pane-page-content .pane-pane-active-facets {
  font-size: 0.94rem;
  a {
    text-decoration: none;
    margin-left: 10px;
    position: relative;
    padding-left: 15px;

    &::before {
      content: "";
      display: inline-block;
      -webkit-transition: -webkit-transform .2s ease-in-out;
      transition: transform .2s ease-in-out;
      background: transparent url(../images/close-blue.svg) no-repeat center center;
      background-size: 9px auto;
      height: 15px;
      width: 13px;
      position: absolute;
      top: 5px;
      left: 0;
    }
    &:hover,
    &:focus {
      &::before {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
  }
  margin: -10px 0 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

/* UI Datepicker */
#ui-datepicker-div {
  border-radius: 0;
  background: #fff;
  border: 0 none;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.2);
  z-index: 400 !important;
  font-family: $base-font-family;
  padding: 0;
  width: 300px;
  table {
    border: 0 none;
    font-size: 14px;
    margin: 0;
    width: 100%;
  }
  .ui-widget-header .ui-icon {
    background-image: url(../images/calendar-icons.png);
  }
  .ui-widget-header .ui-icon-circle-triangle-e {
    background: url(../images/next-white.png) no-repeat center center;
    background-size: 24px auto;
    width: 24px;
    height: 24px;
    margin-left: -13px;
    margin-top: -10px;
  }
  .ui-widget-header .ui-icon-circle-triangle-w {
    background: url(../images/prev-white.png) no-repeat center center;
    background-size: 24px auto;
    width: 24px;
    height: 24px;
    margin-left: -13px;
    margin-top: -10px;
  }
  .ui-datepicker-title select {
    font-size: 14px;
    text-transform: none;
    margin: 0 1%;
    width: 48%;
  }
  tr td:last-child {
    border: 0 none;
  }
  .ui-datepicker-header {
    border-radius: 0;
    background: $darkblue;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    padding: 8px 0;
    .ui-state-hover,
    .ui-state-focus {
      background: transparent;
      border: 1px solid $darkblue;
      cursor: pointer;
    }
    .ui-datepicker-prev-hover {
      left: 2px;
      top: 2px;
    }
    .ui-datepicker-next-hover {
      right: 2px;
      top: 2px;
    }
  }
  th {
    border: 0 none;
    background: transparent;
    font-weight: normal;
    min-width: 0;
  }
  td {
    min-width: 0;
  }
  tbody {
    border-top: 0;
    font-weight: 200;
  }
  td a {
    font-size: 14px;
    text-align: center;
    color: $darkblue;
    padding: 5px;
  }
  td span {
    padding: 5px;
  }
  .ui-state-hover,
  .ui-state-focus {
    border: 0 none;
    background: #f2f2f2;
  }
  .ui-state-highlight {
    border: 0 none;
    background: $lightblue;
    color: #fff;
  }
}

/* Tagcloud */
.tagcloud .item-list {
  ul {
    list-style: none;
    margin: 0;
    li {
      display: inline-block;
      margin: 0;
    }
  }
  a {
    display: block;
    padding: 0.3rem 0.5rem;
    background: $lightblue;
    color: #fff;
    font-size: 0.9rem;
    line-height: 1.1rem;
    -webkit-transition: background-color 0.2s ease;
    -moz-transition: background-color 0.2s ease;
    -ms-transition: background-color 0.2s ease;
    -o-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
    margin-bottom: 0.2rem;
    &:hover,
    &:focus {
      background-color: $darkblue;
    }
    &.facetapi-active {
      background-color: $darkblue;
    }
  }
  .facet-count {
    color: #fff;
  }
}