.node-magazine {

  // General fields
  .field-name-field-magazine-edition h2 {
    color: $orange;
    font-size: 1.9rem;
    line-height: 2.3rem;
    font-weight: 500;
    &::after {
      content: none;
    }
  }
  .field-name-field-magazine-fullpage-image {
    text-align: center;
    img {
      display: inline-block;
    }
  }
  .field-name-field-magazine-link {
    margin-bottom: 2rem;
  }

  .field-name-field-magazine-content {
    font-style: italic;
    font-family: Lora, Georgia, "Times New Roman", Times, serif;

    .label-above {
      font-style: normal;
      font-weight: bold;
      text-transform: uppercase;
      font-family: $base-font-family;
    }
    ul {
      margin: 0 0 1em;
      list-style: none;
    }
    li {
      border-bottom: 3px solid $lightblue;
      padding: 1rem 0;
      margin: 0;
    }
    strong,
    b {
      font-style: normal;
    }
  }

  &.view-mode-full {

  }

  &.view-mode-square {
    h3 a {
      color: $lightblue;
      @extend .arrow-link;
    }
  }
}

.magazine-order-button {
  margin-top: -3rem;
  margin-left: 1rem;
}