.node-project {

  // General fields

  // Square
  &.view-mode-square {
    @extend .box;
    margin-bottom: 1.17rem;
    position: relative;
    .field-name-field-basic-image-image {
      margin: -1.17rem -1.17rem 1.17rem;
      overflow: hidden;
    }
    img {
      width: 100%;
      display: block;
      transition: transform .5s;
      transform: scale(1.01,1.01);
      position: relative;
    }
    h2 {
      margin: 0 0 0.3rem;
    }
    .meta {
      font-style: italic;
      font-size: 15px;
      > div {
        display: inline-block;
        &::before {
          content: "-";
          padding: 0 5px;
        }
        &:first-child::before {
          content: none;
        }
      }
    }
    .field-name-node-link {
      text-align: right;
    }
    &::before {
      background: $yellow;
      width: 100%;
      display: block;
      content: " ";
      height: 0.25rem;
      max-height: 0;
      transition: max-height 0.2s;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 4;
    }
    &:hover {
      &::before {
        max-height: 0.25rem;
      }
      img {
        transform: scale(1,1);
      }
    }
  }

  // List
  &.view-mode-search_result,
  &.view-mode-list {
    .field-name-field-city {
      position: relative;
      padding-left: 30px;
      &::before {
        content: '\f041';
        font-family: FontAwesome;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5rem;
        font-size: 0.94rem;
        font-style: normal;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .field-name-smart-date {
      position: relative;
      padding-left: 30px;
      &::before {
        content: '\f133';
        font-family: FontAwesome;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5rem;
        font-size: 0.94rem;
        font-style: normal;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  // Full
  &.view-mode-full {
  }
}