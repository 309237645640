.twitter-typeahead {
  max-width: 100%;
}
.tt-dropdown-menu {
  background-color: #fff;
  padding: 5px;
  border: 1px solid #f5f5f5;
}
.tt-suggestion {
  padding: 4px;
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
}

// Share buttons
.field-name-share {
  margin-top: 30px;
  clear: both;
  .share42-item {
    a::before {
      content: '\f09a';
      color: #39579b;
      font-family: FontAwesome;
      width: 32px;
      height: 32px;
      display: block;
      font-size: 25px;
      font-weight: normal;
      text-align: center;
    }
  }
  .share42-item:nth-child(2n) a::before {
    content: '\f099';
    color: #56d6fe;
  }
  .share42-item:nth-child(3n) a::before {
    content: '\f0d5';
    color: #ee3e18;
  }
}

/* GDPR popup */
#sliding-popup .popup-content,
#sliding-popup .popup-content #popup-text h2,
#sliding-popup .popup-content #popup-text h3,
#sliding-popup .popup-content #popup-text p {
  font-size: 15px;
}



/* page-admin-administratie-betalingen */
.page-admin-administratie-betalingen .form-actions {
  position: absolute;
  top: 0px;
}
.page-admin-administratie-betalingen .view-header {
  text-align: right;
}
.page-admin-administratie-betalingen .view-filters {
  background-color: lightblue;
  padding: 5px 20px 5px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}
.page-admin-administratie-betalingen .bef-checkboxes .form-item {
  padding: 0;
}

/* Back to top button */
.back-to-top {
  position:fixed;
  background: #333;
  cursor:pointer;
  display:none;
  z-index:1;
  width:40px;
  height:40px;
  right:16px;
  bottom:16px;
  font-size:0;
  line-height:40px;
  color: #a09e98;
  text-align:center;
  vertical-align:middle;
  transition:color .125s ease-out;
  &:before {
    content: '\f062';
    font-size: 12px;
    font-family: FontAwesome;
  }
  &:hover {
    color:#fff;
  }
}
