.node-event {

  // General fields

  // Square
  &.view-mode-square {
    position: relative;
    -webkit-border-radius:2px;
    -moz-border-radius:2px;
    border-radius:2px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.1);
    margin-bottom: 20px;
    padding: 0 0 100% 0;
    height: 0 !important;
    background: $light;
    overflow: hidden;

    &::after {
      content: " ";
      width: 100%;
      height: 150px;
      background: linear-gradient(to bottom,transparent 0%,rgba(0,0,0,0.6) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000',endColorstr='#cc000000',GradientType=0);
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      visibility: visible;
      display: block;
    }
    img {
      width: 100%;
      display: block;
      transition: transform .5s;
      transform: scale(1.01,1.01);
      position: relative;
    }
    .meta {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 1rem 1rem;
      overflow: hidden;
      color: #fff;
      z-index: 3;
      font-size: 1.05rem;
      font-style: italic;
      margin-bottom: 0;
      -moz-transition: margin-bottom .5s;
      -webkit-transition: margin-bottom .5s;
      transition: margin-bottom .5s;
      a {
        color: #fff;
      }
      h2 {
        font-size: 1.23rem;
        line-height: 1.53rem;
        text-transform: none;
        margin: 0;
        font-style: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &::after {
          content: none;
        }
      }
    }
    .field-name-smart-date,
    .field-name-field-city {
      display: inline-block;
    }
    .field-name-field-city {
      &::before {
        content: "-";
        padding: 0 5px;
      }
    }
    &::before {
      background: $yellow;
      width: 100%;
      display: block;
      content: " ";
      height: 0.25rem;
      max-height: 0;
      -webkit-transition: max-height 0.2s;
      -moz-transition: max-height 0.2s;
      -ms-transition: max-height 0.2s;
      -o-transition: max-height 0.2s;
      transition: max-height 0.2s;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 4;
    }
    &:hover {
      &::before {
        max-height: 0.25rem;
      }
      img {
        transform: scale(1,1);
      }
      .meta {
        margin-bottom: 0.11rem;
      }
    }
  }

  // List
  &.view-mode-search_result,
  &.view-mode-list {
    background: #fff;
    margin-bottom: 0;
    border-bottom: 1px solid $grey;
    padding: 1.5rem 0 1.5rem 0.2rem;
    h2 a {
      transition: color 0.2s;
    }
    &:hover {
      h2 a {
        color: $lightblue;
      }
    }
    &::before {
      content: none;
    }
    .field-name-node-link {
      text-align: left;
    }
    .col-content {
      padding-top: 0.29rem;
      padding-right: 1rem;
    }
    .field-name-field-city {
      position: relative;
      padding-left: 30px;
      &::before {
        content: '\f041';
        font-family: FontAwesome;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5rem;
        font-size: 0.94rem;
        font-style: normal;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .field-name-smart-date {
      position: relative;
      padding-left: 30px;
      &::before {
        content: '\f133';
        font-family: FontAwesome;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5rem;
        font-size: 0.94rem;
        font-style: normal;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  // Full
  &.view-mode-full {
    .field {
      margin-bottom: 1rem;
    }
    .detail-header {
    }
    .field-name-smart-image {
      width: 100%;
      @include media-breakpoint-up(sm) {
        float: right;
        max-width: 40%;
        margin-left: 1rem;
      }
    }
    .field-name-smart-date {
      position: relative;
      padding-left: 30px;
      &::before {
        content: '\f133';
        font-family: FontAwesome;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5rem;
        font-size: 0.94rem;
        font-style: normal;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .field-name-detailed-info {
      > div {
        position: relative;
        padding-left: 30px;
        margin-bottom: 1rem;
        &::before {
          content: '\f041';
          font-family: FontAwesome;
          display: inline-block;
          vertical-align: top;
          margin-right: 0.5rem;
          font-size: 0.94rem;
          font-style: normal;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      .contact {
        &:before {
          content: '\f0e0';
        }
      }
      .reservation {
        &:before {
          content: '\f145';
        }
      }
    }
    .field-name-price {
      position: relative;
      padding-left: 30px;
      &::before {
        content: '\f0d6';
        font-family: FontAwesome;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5rem;
        font-size: 0.94rem;
        font-style: normal;
        position: absolute;
        left: 0;
        top: 0;
      }
      .prices-extra {
        .prices-inner {
          display: none;
        }
        &.open .prices-inner {
          display: block;
        }
      }
    }
    .field-name-reservation {
      margin: 1rem 0 -45px;
      text-align: center;
    }
  }
  // Title
  &.view-mode-title {
    position: relative;
    border-bottom: 1px solid $darkblue;
    padding: 0.8rem;
    background: #fff;
    margin: 0;
    &:last-child {
      border-bottom: 0 none;
    }
    p {
      margin: 0;
    }
    .field-name-smart-title a {
      color: $darkblue;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.82rem;
    }
    .field-name-smart-date {
      position: relative;
      padding-left: 30px;
      font-size: 0.82rem;
      font-style: italic;
      &::before {
        content: '\f133';
        font-family: FontAwesome;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5rem;
        font-size: 0.94rem;
        font-style: normal;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .field-name-field-city {
      margin-left: 1.3rem;
      position: relative;
      padding-left: 20px;
      &::before {
        content: '\f041';
        font-family: FontAwesome;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5rem;
        font-size: 0.94rem;
        font-style: normal;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .meta {
      .field-name-smart-date,
      .field-name-field-city {
        display: inline-block;
      }
    }
  }
}
