/* Media */
.field-name-field-image-caption,
.media-element-container {
  margin: 8px 0 30px 0;
  font-size: 16px;
  line-height: 20px;
}

.media-element-container {
  max-width: 100%;
  @extend .clearfix;
}

/* Half width image style */
.media-half_width {
  height: auto;
  @include media-breakpoint-up(xs) {
    max-width: 50%;
    width: 50%;
  }
  img.panopoly-image-half,
  img.half-width,
  img.file-half-width {
    width: auto;
    max-width: 100%;
    margin: 0 0 10px 0;
    float: none;
  }
}
.cke_editable img.file-half-width {
  max-width: 50%;
  width: 50%;
}

/* Full width image style */
.media-full_width {
  height: auto;
  @include media-breakpoint-up(xs) {
    max-width: 95%;
    width: 95%;
  }
  img.panopoly-image-full,
  img.full-width,
  img.file-full-width {
    width: 100%;
    max-width: 100%;
    margin: 0;
    float: none;
  }
}
.cke_editable img.file-full-width {
  max-width: 90%;
  width: 90%;
}

/* Quarter width image style */
.media-quarter_width {
  height: auto;
  @include media-breakpoint-up(xs) {
    max-width: 25%;
    width: 25%;
  }
  img.panopoly-image-quarter,
  img.quarter-width,
  img.file-quarter-width {
    width: auto;
    max-width: 100%;
    margin: 0;
    float: none;
  }
}
.cke_editable img.file-quarter-width {
  max-width: 25%;
  width: 25%;
}

/* Third width image style */
.media-third {
  height: auto;
  @include media-breakpoint-up(xs) {
    max-width: 33%;
    width: 33%;
  }
  img.third-width,
  img.file-third {
    width: auto;
    max-width: 100%;
    margin: 0;
    float: none;
  }
}
.cke_editable img.file-third {
  max-width: 33%;
  width: 33%;
}

.file img.file-icon {
  display: inline-block !important;
  margin: 0 0.4rem 0 0 !important;
  *zoom: 1;
  *display: inline;
  vertical-align: bottom;
  height: 1.7rem;

}

.media-wysiwyg-align-left,
.media-float-left {
  float: none;
  margin-right: 0;
  margin-bottom: 20px;
  @include media-breakpoint-up(xs) {
    float: left;
    margin-right: 30px;
  }
}
.media-wysiwyg-align-right,
.media-float-right {
  float: none;
  margin-left: 0;
  margin-bottom: 20px;
  @include media-breakpoint-up(xs) {
    float: right;
    margin-left: 30px;
  }
}
